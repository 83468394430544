<template>
  <v-dialog
    persistent
    :value="open"
    scrollable
    width="38%"
    attach="#main-container"
  >
    <v-card class="br-6" min-height="535px">
      <v-card-text class="pa-4 pb-6">
        <v-row no-gutters>
          <v-col
            v-if="showCloseBtn"
            class="d-flex w-100 justify-space-between align-center"
          >
            <span class="h5 semiBold"></span>
            <v-btn v-if="!loading" @click="$emit('close')" icon>
              <Icon name="close" size="25px" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <div
              class="d-flex flex-column align-center"
              v-if="data?.success && !loading"
            >
              <Icon name="check_circle" size="80px" class="icon-success" />
              <Alert variant="success" class="w-100 mt-2">
                <template slot="content">
                  <div>
                    <span class="p">Póliza generada con éxito </span>
                  </div>
                </template>
              </Alert>

              <p>
                REF: <b>{{ data?.data?.poliza ?? "" }}</b>
              </p>
            </div>

            <div
              v-else-if="loading"
              class="d-flex flex-column align-center mt-2"
            >
              <v-progress-circular
                :rotate="-60"
                :size="80"
                :width="10"
                indeterminate
                class="primary--text"
              >
                <Icon name="draft" size="20px" />
              </v-progress-circular>
              <span class="p mt-2"> Estamos generando su póliza</span>
            </div>

            <div v-else class="d-flex flex-column align-center mt-2">
              <Icon name="error" size="80px" class="icon-warning" />
              <Alert variant="warning" class="w-100 mt-2">
                <template slot="content">
                  <div>
                    <span class="p">
                      La operación ha sido registrada con éxito. Sin embargo,
                      tuvimos un problema en la generación del documento que
                      respalda la póliza. <br /><br />
                      Seguiremos trabajando en su emisión y le
                      <span class="semiBold"
                        >enviaremos la póliza al correo electrónico del
                        cliente</span
                      >
                      a la brevedad.
                    </span>
                  </div>
                </template>
              </Alert>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-card class="light w-100" elevation="0">
              <v-card-text>
                <v-alert
                  type="error"
                  v-if="!data?.success && !loading"
                  outlined
                  dense
                >
                  {{ data?.message ?? "" }}
                </v-alert>

                <ul v-if="loading">
                  <li>El envío del token se encuentra en progreso.</li>
                  <li>Una vez verificado el token, la poliza será generada.</li>
                  <li>
                    Podrá ver la información de su gestión una vez finalizado el
                    proceso.
                  </li>
                </ul>
                <ul v-else-if="!data?.success">
                  <li>
                    Revisa en el historial de tus gestiones para confirmar que
                    todo quedo bien.
                  </li>
                  <li>
                    Si no queda regularizado en los próximos minutos contacta al
                    equipo de soporte escribiendo al:
                    <span class="bold">soporte.plataforma@mesos.cl</span> o
                    llamando al <span class="bold">227564013.</span>
                  </li>
                </ul>
                <ul v-else>
                  <li>
                    Podrás ver la póliza al ingresar el rut del Cliente en el
                    Inicio
                  </li>
                  <li>Refuerza con el Cliente los canales de atención</li>
                  <li>
                    Recuérdale al Cliente estar atento a las comunicaciones que
                    le enviaremos para estar siempre informado del estado de su
                    seguro
                  </li>
                  <li>
                    Recuerda revisar y adjuntar todos los documentos pendientes
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <template v-if="!loading">
          <v-row v-if="data?.success">
            <!-- <v-col>
            <div class="d-flex flex-column justify-center">
              <v-btn text class="text-none" color="primary">
                <Icon name="upload" size="20px" />
                <span class="ml-2">Adjuntar documentos pendientes</span>
              </v-btn>
              <v-btn text class="text-none" color="primary">
                <Icon name="share" size="20px" />
                <span class="ml-2">Enviar póliza por email </span>
              </v-btn>
            </div>
          </v-col> -->

            <v-col>
              <div class="d-flex justify-space-around">
                <!-- <v-btn color="primary" outlined rounded class="text-none"
                >Imprimir póliza</v-btn
              > -->
                <v-btn
                  color="primary"
                  rounded
                  class="text-none px-6"
                  :disabled="!data?.data?.descargarPoliza"
                  @click="handlerDownloadDocument()"
                  >Descargar póliza</v-btn
                >
                <v-btn
                  v-if="showBtnEntendido"
                  @click="$emit('close')"
                  rounded
                  color="primary"
                  class="text-none px-6"
                >
                  Entendido
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <div class="d-flex justify-center">
                <v-btn
                  v-if="showBtnEntendido"
                  @click="$emit('close')"
                  rounded
                  color="primary"
                  class="text-none"
                  width="170"
                >
                  Entendido
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";
import Alert from "@/components/Alert/Alert.vue";

import { downloadPdfFile } from "@/services/file.js";

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      polizaSuccess: false,
      polizaError: false,
    };
  },
  components: {
    Icon,
    Alert,
  },
  methods: {
    handlerDownloadDocument() {
      const doc = this.data?.data?.documento_poliza ?? {};
      downloadPdfFile(
        doc?.data_archivo,
        doc?.tipo_archivo ?? "pdf",
        `${doc?.documento ?? "POL"}_${this.data?.data?.poliza ?? ""}`
      );
    },
  },
  computed: {
    userChannelInfo() {
      return this.$store.getters.getGeneralInfo;
    },

    accesoPantallas() {
      return this.userChannelInfo?.personalizacion_corporativa
        ?.acceso_pantallas;
    },

    showBtnEntendido() {
      return (
        this.accesoPantallas?.find(
          (v) => v.seccion === "FIRMA_DIGITAL" && v.codigo === "BOTON_ENTENDIDO"
        )?.activo ?? true
      );
    },

    showCloseBtn() {
      return (
        this.accesoPantallas?.find(
          (v) =>
            v.seccion === "PROCESO_FIRMA" && v.codigo === "BOTON_CERRAR_MODAL"
        )?.activo ?? true
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
</style>
