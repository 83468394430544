<template>
  <div>
    <v-form ref="form">
      <v-layout>
        <v-flex xs6 class="mr-4">
          <LabelComponent text="N° documento particular*" />
          <TextFieldRut
            :text="form.numero_documento"
            @input="form.numero_documento = $event"
            color="#0077C8"
            :disabled="loading"
            :rules="[rules.required, rules.rut, rules.isValid]"
            name="rut_ben" />
        </v-flex>
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Nombre*" />
          <inputText
            placeholder="Nombre"
            :model="form.nombre"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.nombre = $event"
            :rules="[rules.required]"
            :hide="false"
            name="nombre_ben" />
        </v-flex>
      </v-layout>
      <v-layout class="mt-1">
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Primer apellido*" />
          <inputText
            placeholder="Primer apellido"
            :model="form.primer_apellido"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.primer_apellido = $event"
            :rules="[rules.required]"
            :hide="false"
            name="fst_apellido_ben" />
        </v-flex>
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Segundo apellido*" />
          <inputText
            placeholder="Segundo apellido"
            :model="form.segundo_apellido"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.segundo_apellido = $event"
            :rules="[rules.required]"
            :hide="false"
            name="snd_apellido_ben" />
        </v-flex>
      </v-layout>
      <v-layout justify-center class="mt-1">
        <v-flex xs6 class="mr-4">
          <!-- CAMPO DATE CON CALENDARIO -->
          <!-- <DatePicker
          label="Fecha de nacimiento"
          :date="form.fecha_nacimiento"
          @input="form.fecha_nacimiento = $event"
          color="#0077c8"
          :disabled="false"
          :hide="false"
          :min="false"
          :max="false"
        /> -->
          <!-- CAMPO DATE SIN CALENDARIO -->
          <LabelComponent text="Fecha de nacimiento*" />
          <v-text-field
            v-model="form.fecha_nacimiento"
            :rules="[rules.required, rules.date, rules.date18]"
            placeholder="dd/mm/yyyy"
            :disabled="loading"
            v-mask="dateMask"
            outlined
            dense
            name="fecha_nacimiento_ben"></v-text-field>
        </v-flex>

        <v-flex xs6 class="ml-4">
          <LabelComponent text="Género*" />
          <v-select
            :items="genre"
            v-model="form.genero"
            dense
            append-icon="mdi-chevron-down"
            outlined
            name="genero_aseg"
            :rules="[rules.required]"></v-select>
        </v-flex>
      </v-layout>
      <v-layout justify-center class="mt-1">
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Correo*" />
          <inputText
            placeholder="Correo"
            :model="form.correo"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.correo = $event"
            :rules="[rules.required, rules.email]"
            :hide="false"
            name="correo_ben" />
        </v-flex>
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Teléfono*" />
          <TextFieldPhone
            :text="form.telefono"
            :rules="[rules.required, rules.telefono]"
            @input="form.telefono = $event"
            name="telefono_ben" />
          <!-- <inputText
            placeholder="Teléfono"
            :model="form.telefono"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.telefono = $event"
            :rules="[rules.required, rules.telefono]"
            :hide="false"
            name="telefono_ben"
          /> -->
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Comuna*" />
          <v-autocomplete
            :items="cities"
            item-text="label"
            v-model="form.comuna"
            :rules="[rules.required]"
            name="comuna_aseg"
            outlined
            append-icon="mdi-chevron-down"
            dense></v-autocomplete>
        </v-flex>

        <v-flex xs6 class="ml-4">
          <LabelComponent text="Dirección*" />
          <inputText
            placeholder="Dirección"
            :model="form.direccion"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.direccion = $event"
            :rules="[rules.required]"
            :hide="false"
            name="direccion_ben" />
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Número*" />
          <inputText
            placeholder="Número"
            :model="form.numero"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.numero = $event"
            :rules="[rules.required]"
            :hide="false"
            name="numero_ben" />
        </v-flex>
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Departamento/Casa" />
          <inputText
            placeholder="Departamento/Casa"
            :model="form.piso"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.piso = $event"
            :hide="false"
            name="dpto_ben" />
        </v-flex>
      </v-layout>

      <!-- CAMPO DE INFORMACION EXTRA -->
      <!-- Descomentar si se requiere nuevamente -->
      <!-- <v-layout v-if="showOther" justify-start class="mb-15 mt-1 mr-8">
      <v-flex xs6>
        <inputText
          color="#0077c8"
          :model="form.otro"
          readonly="false"
          label="Otro"
          @input="form.otro = $event"
          size="large"
        />
      </v-flex>
    </v-layout>
    <v-layout v-if="!showOther" wrap justify-center class="mt-1">
      <v-flex xs12>
        <v-layout justify-start>
          <v-flex xs6>
            <v-btn color="primary" text small @click="displayOther">
              <v-icon left>bi bi-plus-circle</v-icon> Añadir otro
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout> -->
    </v-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import inputText from "@/components/Inputs/Input";
// import DatePicker from "@/components/Inputs/DatePicker";
import { dateMask, rutMask } from "@/helpers/mask.js";
import { dateToNumber } from "@/store/resources/calc.js";
import { genreList } from "@/store/resources/constants.js";
import LabelComponent from "@/components/Inputs/Label.vue";
import TextFieldPhone from "@/components/Inputs/TextFieldPhone";
import TextFieldRut from "@/components/Inputs/TextFieldRut";

import {
  validarFecha,
  validarEmail,
  validarRutFormat,
  validarNumerico,
  validarRut,
  validarFecha18,
} from "@/store/resources/validate.js";
export default {
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.setup();
  },
  data() {
    return {
      loading: false,
      // showOther: false,
      form: {
        tipo_documento: "RUT",
        cotizacion: "",
        numero_documento: "",
        nombre: "",
        primer_apellido: "",
        segundo_apellido: "",
        fecha_nacimiento: "",
        genero: "",
        comuna: "",
        direccion: "",
        numero: "",
        piso: "",
        correo: "",
        telefono: "",
        interes: "",
        otro: "",
      },
      rules: {
        required: (value) => !!value || "Campo requerido.",
        date: (value) => validarFecha(value) || "Formato de fecha incorrecto",
        date18: (value) => validarFecha18(value) || "Debe ser mayor de edad",
        rut: (value) => validarRutFormat(value) || "Formato de RUT invalido",
        email: (value) => validarEmail(value) || "Correo invalido.",
        numero: (value) => validarNumerico(value) || "Número invalido",
        telefono: (value) => value.length == 9 || "Teléfono inválido",
        isValid: (value) =>
          validarRut(value) || "No se ha logrado reconocer el RUT",
      },
    };
  },
  methods: {
    setup() {
      // ====== RELLENAR DATOS DEL BENEFICIARIO ======= //
      this.form.tipo_documento = this.formData?.tipo_documento ?? "RUT";
      this.form.numero_documento = this.formData?.numero_documento ?? "";
      this.form.nombre = this.formData?.nombre ?? "";
      this.form.primer_apellido = this.formData?.primer_apellido ?? "";
      this.form.segundo_apellido = this.formData?.segundo_apellido ?? "";
      this.form.fecha_nacimiento = dateToNumber(
        this.formData?.fecha_nacimiento ?? ""
      );
      this.form.genero = this.formData?.genero ?? "";
      this.form.comuna = this.formData?.comuna ?? "";
      this.form.direccion = this.formData?.direccion ?? "";
      this.form.numero = this.formData?.numero ?? "";
      this.form.piso = this.formData?.piso ?? "";
      this.form.correo = this.formData?.correo ?? "";
      this.form.telefono = this.formData?.telefono ?? "";
      this.form.interes = this.formData?.interes ?? "";
      this.form.otro = this.formData?.otro ?? "";
    },

    // displayOther() {
    //   this.showOther = !this.showOther;
    // },
  },
  computed: {
    ...mapGetters("Proposal", ["getCitiesTwo"]),
    rutMask: () => rutMask,
    dateMask: () => dateMask,
    genre: () => genreList,
    cities() {
      return this.getCitiesTwo.answers;
    },
  },
  components: {
    inputText,
    // DatePicker,
    LabelComponent,
    TextFieldPhone,
    TextFieldRut,
  },
};
</script>
