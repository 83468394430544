<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="6" class="px-6 h75">
          <!-- CAMPO RUT -->
          <LabelComponent :text="documentNumberLabel" />
          <v-text-field
            v-model="form.numero_documento"
            class="pt-0"
            name="rut_aseg"
            outlined
            disabled
            dense
            v-mask="rutMask"
            :return-masked-value="false"
            :rules="[rules.required, rules.rut, rules.isValid]"></v-text-field>
        </v-col>

        <!-- CAMPO NOMBRE -->
        <v-col
          cols="12"
          md="6"
          class="px-6 h75"
          v-if="formData?.tipo_documento !== 'JUR'">
          <LabelComponent text="Nombre*" />
          <v-text-field
            name="nombre_aseg"
            class="py-0"
            outlined
            disabled
            dense
            v-model="form.nombre"
            :rules="[rules.required]"
            placeholder="Nombre"></v-text-field>
        </v-col>
        <!-- CAMPO NOMBRE -->
        <v-col cols="12" md="6" class="px-6 h75" v-else>
          <LabelComponent text="Razón Social*" />
          <v-text-field
            name="nombre_aseg"
            class="pt-0"
            outlined
            disabled
            dense
            v-model="form.razon_social"
            :rules="[rules.required]"
            placeholder="Nombre"></v-text-field>
        </v-col>

        <template
          v-if="country === countries.PE && form.tipo_documento === 'JUR'">
          <v-col cols="12" md="6" class="px-6 h75">
            <LabelComponent text="Giro*" />
            <v-text-field
              name="nombre_aseg"
              class="pt-0"
              outlined
              disabled
              dense
              v-model="form.giro"
              :rules="[rules.required]"
              placeholder="Nombre"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="px-6 h75">
            <LabelComponent text="Actividad comercial*" />
            <v-text-field
              name="nombre_aseg"
              class="pt-0"
              outlined
              disabled
              dense
              v-model="form.actividad_comercial"
              :rules="[rules.required]"
              placeholder="Nombre"></v-text-field>
          </v-col>
        </template>

        <template v-if="!['RUC'].includes(form.tipo_documento)">
          <!-- CAMPO PRIMER APELLIDO -->

          <v-col cols="12" md="6" class="px-6 h75">
            <LabelComponent text="Primer apellido*" />
            <v-text-field
              name="fst_apellido_aseg"
              class="pt-0"
              disabled
              outlined
              dense
              v-model="form.primer_apellido"
              :rules="[rules.required]"
              placeholder="Primer apellido"></v-text-field>
          </v-col>

          <!-- CAMPO SEGUNDO APELLIDO -->
          <v-col cols="12" md="6" class="px-6 h75">
            <LabelComponent text="Segundo apellido*" />
            <v-text-field
              name="snd_apellido_aseg"
              v-model="form.segundo_apellido"
              :rules="[rules.required]"
              class="pt-0"
              disabled
              outlined
              dense
              placeholder="Segundo apellido"></v-text-field>
          </v-col>
          <!-- CAMPO FECHA NAC -->
          <v-col cols="12" md="6" class="px-6 h75">
            <!-- CAMPO DATE CON CALENDARIO -->
            <DatePicker
              label="Fecha de nacimiento "
              :date="form.fecha_nacimiento"
              @input="form.fecha_nacimiento = $event"
              :disabled="true"
              color="#0077c8"
              :hide="false"
              :min="false"
              :max="false" />
            <!-- CAMPO DATE SIN CALENDARIO -->
            <LabelComponent text="Fecha de nacimiento*" />
            <v-text-field
              name="fecha_nac_aseg"
              class="pt-0"
              outlined
              dense
              v-model="form.fecha_nacimiento"
              placeholder="Fecha de nacimiento"
              :rules="[rules.required, rules.date, rules.date18]"
              disabled
              v-mask="dateMask"></v-text-field>
            <!-- <inputText
            placeholder="dd/mm/yyyy"
            :model="form.fecha_nacimiento"
            color="#0077C8"
            size="large"
            readonly="true"
            :rules="[rules.required]"
            :hide="false"
            return-masked-value
            v-mask="dateMask"
            name="fecha_nac_aseg"
          /> -->
          </v-col>

          <!-- CAMPO GENERO -->
          <v-col cols="12" md="6" class="px-6 h75">
            <LabelComponent text="Género*" />
            <v-select
              :items="genre"
              v-model="form.genero"
              disabled
              outlined
              dense
              append-icon="mdi-chevron-down"
              class="pt-0"
              name="genero_aseg"
              :rules="[rules.required]"></v-select>
          </v-col>
        </template>

        <!-- CAMPO CORREO -->
        <v-col cols="12" md="6" class="px-6 h75">
          <LabelComponent text="Correo*" />
          <v-text-field
            name="correo_aseg"
            disabled
            v-model="form.correo"
            outlined
            dense
            class="pt-0"
            placeholder="Correo"
            :rules="[rules.required, rules.email]"></v-text-field>
        </v-col>

        <!-- CAMPO TELEFONO -->
        <v-col cols="12" md="6" class="px-6 h75">
          <LabelComponent text="Teléfono*" />
          <v-text-field
            name="telefono_aseg"
            v-model="form.telefono"
            outlined
            dense
            placeholder="Teléfono"
            :rules="[rules.required, rules.telefono]"
            disabled
            class="pt-0"></v-text-field>
        </v-col>
        <template v-if="country === countries.CL">
          <v-col cols="12" md="6" class="px-6 h75">
            <LabelComponent text="Comuna*" />
            <v-autocomplete
              :items="comunas"
              outlined
              dense
              item-text="nombre"
              item-value="codigo"
              return-object
              disabled
              v-model="form.comuna"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              name="comuna_aseg"
              class="pt-0"></v-autocomplete>
          </v-col>

          <!-- CAMPO DE INFORMACION EXTRA -->
          <!-- Descomentar si se requiere nuevamente -->
          <!-- @change="(val) => handleChange(val)" -->
          <!-- <v-col
        xs6
        :class="[
          tarifa.interes_asegurable ? 'ml-8' : 'order-first mr-8',
          'align-self-center',
        ]"
      >
        <inputText
          v-if="!showOther"
          color="#0077c8"
          :model="insured.otro"
          readonly="false"
          label="Otro"
          @input="insured.otro = $event"
        />
        <v-btn color="primary" small text @click="displayOther" v-else>
          <v-icon left>bi bi-plus-circle</v-icon> Añadir otro
        </v-btn>
      </v-col> -->
        </template>
        <template v-if="country === countries.PE">
          <v-col cols="12" md="6" class="px-6 h75">
            <LabelComponent text="Departamento*" />
            <v-select
              :items="regiones"
              outlined
              dense
              item-text="nombre"
              item-value="codigo"
              v-model="form.region"
              append-icon="mdi-chevron-down"
              name="depto_aseg"
              disabled
              class="pt-0" />
          </v-col>
          <v-col cols="12" md="6" class="px-6 h75">
            <LabelComponent text="Provincia*" />
            <v-select
              :items="provincias"
              outlined
              dense
              item-text="nombre"
              item-value="codigo"
              disabled
              v-model="form.provincia"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              name="provincia_aseg"
              class="pt-0" />
          </v-col>

          <v-col cols="12" md="6" class="px-6 h75">
            <LabelComponent text="Distrito*" />
            <v-select
              :items="comunas"
              outlined
              dense
              item-text="nombre"
              selected="quotation.comuna"
              item-value="codigo"
              disabled
              v-model="form.comuna"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              name="distrito_aseg"
              class="pt-0" />
          </v-col>
        </template>

        <v-col cols="12" md="6" class="px-6 h75">
          <LabelComponent text="Dirección*" />
          <v-text-field
            name="direccion_aseg"
            class="pt-0"
            outlined
            dense
            v-model="form.direccion"
            :rules="[rules.required]"
            placeholder="Nombre" />
        </v-col>
        <v-col cols="12" md="6" class="px-6 h75">
          <LabelComponent text="Número*" />
          <v-text-field
            name="numero_aseg"
            class="pt-0"
            outlined
            dense
            v-model="form.numero"
            :rules="[rules.required]"
            placeholder="Nombre" />
        </v-col>
        <v-col cols="12" md="6" class="px-6 h75">
          <LabelComponent :text="$t('deptoCasa')" />
          <v-text-field
            name="dpto_aseg"
            class="pt-0"
            outlined
            dense
            v-model="form.piso"
            placeholder="Nombre" />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="px-6 h75"
          v-if="
            interesAsegurable &&
            country === countries.CL &&
            form.tipo_documento === 'RUT'
          ">
          <LabelComponent :text="select_interes.label" />
          <v-select
            v-model="form.interes"
            :items="getInsurableInterestList"
            :loading="loading_interest"
            :disabled="loading_interest"
            outlined
            dense
            item-value="codigo"
            item-text="interes_descripcion"
            class="mr-8"
            append-icon="mdi-chevron-down"
            item-disabled="eliminado"
            :rules="[rules.required]"
            single-line
            name="interes_aseg"></v-select>
        </v-col>
      </v-row>
    </v-form>
    <ModalInteres v-if="interesModal" :toggle="toggleModal" />
  </div>
</template>
<script>
// import DatePicker from "@/components/Inputs/DatePicker";
// import moment from "moment";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { dateMask, rutMask } from "@/helpers/mask.js";
import { genreList } from "@/store/resources/constants.js";
import { dateToNumber } from "@/store/resources/calc.js";
import LabelComponent from "@/components/Inputs/Label.vue";
import ModalInteres from "@/components/Modal/Modal-interes";
import { COUNTRIES } from "@/helpers/constants";

import {
  validarFecha,
  validarEmail,
  validarRutFormat,
  validarNumerico,
  validarFecha18,
  validarRut,
} from "@/store/resources/validate.js";
export default {
  name: "InsuredForm",
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    email: {
      type: String,
      default: undefined,
    },
    phone: {
      type: [String, Number],
      default: undefined,
    },
    interesAsegurable: {
      type: Boolean,
      default: false,
    },
    quotation: {
      type: Object,
    },
  },

  components: {
    // DatePicker,
    LabelComponent,
    ModalInteres,
  },

  data() {
    return {
      countries: COUNTRIES,
      loading: false,
      validForm: false,
      interesModal: false,
      form: {
        comuna: "",
        correo: "",
        cotizacion: "",
        direccion: "",
        fecha_nacimiento: "",
        genero: "",
        interes: "",
        nombre: "",
        numero_documento: "",
        numero: "",
        otro: "",
        region: "",
        piso: "",
        primer_apellido: "",
        provincia: "",
        segundo_apellido: "",
        telefono: "",
        tipo_documento: "",
      },
      select_interes: {
        label: "Interés asegurable",
        placeholder: "Seleccione uno...",
      },
      loading_interest: false,
      // showOther: false,
      rules: {
        required: (value) => !!value || "Campo requerido.",
        rut: (value) => validarRutFormat(value) || "RUT invalido",
        date: (value) => validarFecha(value) || "Formato de fecha incorrecto",
        email: (value) => validarEmail(value) || "Correo invalido.",
        numero: (value) => validarNumerico(value) || "Número inválido",
        telefono: (value) => value.length == 9 || "Teléfono inválido",
        // interes: (value) => value === "D" || "Por favor revise la información",
        date18: (value) => validarFecha18(value) || "Debe ser mayor de edad",
        isValid: (value) =>
          validarRut(value) || "No se ha logrado reconocer el RUT",
      },
    };
  },

  created() {
    this.setup();
  },
  watch: {
    "form.interes": function (newValue) {
      if (newValue !== "D") {
        this.interesModal = true;
      }
    },
  },

  methods: {
    ...mapActions("Proposal", ["REQUEST_INSURABLE_INTEREST"]),
    ...mapMutations("Forms", ["updateFormField"]),
    toggleModal() {
      this.interesModal = !this.interesModal;
    },
    // handleChange(val) {
    //   if (val.codigo !== "D") {
    //     this.interesModal = true;
    //   }
    // },
    updateValue(field, value) {
      this.updateFormField({ formularioId: "ensured", field, value });
    },
    setup() {
      if (this.tarifa && this.tarifa.interes_asegurable) {
        this.setupInterest();
      }

      this.form.tipo_documento = this.formData?.tipo_documento || "";
      this.form.numero_documento = this.formData?.numero_documento || "";
      this.form.nombre = this.formData?.nombre || this.formData?.nombres || "";
      this.form.primer_apellido = this.formData?.primer_apellido || "";
      this.form.segundo_apellido = this.formData?.segundo_apellido || "";
      this.form.fecha_nacimiento = dateToNumber(
        this.formData?.fecha_nacimiento ?? ""
      );
      this.form.correo =
        this.formData?.correo ||
        this.formData?.correo_cliente ||
        (this.email ?? "");
      this.form.telefono =
        this.formData?.telefono ||
        this.formData?.telefono_cliente ||
        (this.phone ?? "");
      this.form.genero = this.formData?.genero ?? "";
      // Campos rellenables

      this.form.comuna =
        this.comunas.find((comuna) => comuna._id === this.formData?.comuna) ||
        this.comunas.find(
          (comuna) => comuna.codigo === this.formData?.comuna
        ) ||
        this.quotation?.comuna;
      this.form.region =
        this.regiones.find((region) => region._id === this.quotation.region) ||
        this.quotation?.region;
      this.form.provincia =
        this.provincias.find(
          (provincia) => provincia._id === this.quotation.provincia
        ) || this.quotation?.provincia;
      this.form.direccion = this.formData?.direccion || "";
      this.form.numero = this.formData?.numero || "";
      this.form.piso = this.formData?.piso || "";
      this.form.otro = this.formData?.otro || "";
    },

    setupInterest() {
      this.loading_interest = true;
      this.REQUEST_INSURABLE_INTEREST()
        .then(() => {
          this.form.interes =
            this.getInsurableInterestList.find(
              (e) => e.codigo == this.formData?.interes
            )?.codigo ?? "D";
        })
        .finally(() => (this.loading_interest = false));
    },
    // displayOther() {
    //   this.showOther = !this.showOther;
    // },
  },
  computed: {
    ...mapGetters("Proposal", [
      // "getCities",
      "getInsurableInterestList",
      "tarifa",
    ]),
    ...mapGetters("Internationalization", [
      "comunas",
      "regiones",
      "provincias",
      "tipoDocumentos",
    ]),
    dateMask: () => dateMask,
    rutMask() {
      return rutMask;
    },

    genre: () => genreList,

    country() {
      return this.$store.getters.getCountry;
    },
    documentNumberLabel() {
      let label = "N° documento particular";
      if (this.country === this.countries.CL) {
        if (this.form.tipo_documento === "RUT") {
          label = "N° documento particular";
        } else {
          label = "N° documento comercial";
        }
      } else if (this.country === this.countries.PE) {
        if (this.form.tipo_documento === "RUC") {
          label = "RUC";
        } else if (this.form.tipo_documento === "DNI") {
          label = "DNI";
        }
      }
      return label;
    },
  },
};
</script>
<style>
.v-messages__message {
  color: #ff5252 !important;
}

.h75 {
  max-height: 75px;
}
</style>
