<template>
  <div class="mt-8">
    <span>
      Para continuar con la inspección a domicilio, <b>confirme</b> los
      siguientes datos:
    </span>
    <v-form ref="form" class="mt-4">
      <v-row no-gutters>
        <v-col cols="12" md="8">
          <LabelComponent text="Dirección particular o comercial*" />
          <TextField :fields="Address" name="direccion" />
          <div>
            <LabelComponent :text="`${$t('commune')}*`" />
            <v-autocomplete
              outlined
              dense
              item-text="label"
              v-model="Commune.selected"
              append-icon="mdi-chevron-down"
              :items="cities?.answers ?? []"
              name="comuna_inspec">
            </v-autocomplete>
          </div>
          <LabelComponent text="Nombre de contacto*" />
          <TextField :fields="Contact" name="contacto" />
          <LabelComponent text="Teléfono de contacto*" />
          <TextFieldPhone
            :text="Phone.text"
            @input="Phone.text = $event"
            :rules="Phone.rules"
            name="telefono" />
          <v-row>
            <v-col>
              <LabelComponent text="Fecha sugerida*" />
              <DatePicker
                :date="date"
                @input="date = $event"
                color="#A9A9A9"
                :hide="false" />
            </v-col>
            <v-col>
              <LabelComponent text="Hora sugerida*" />
              <TimePicker
                @input="date = $event"
                color="#A9A9A9"
                :hide="false" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <div class="my-6">
      <Warning />
    </div>
    <div class="mt-8 text-center">
      <v-btn
        color="primary"
        class="mx-1 text-none"
        outlined
        rounded
        @click="handleBack()"
        :disabled="loadingBtn"
        width="140"
        name="boton_volver"
        >Volver</v-btn
      >
      <v-btn
        color="primary"
        rounded
        @click="HandleInspectAtHome()"
        :loading="loadingBtn"
        class="mx-1 text-none"
        name="boton_continuar_local"
        width="140"
        >Continuar</v-btn
      >
    </div>

    <SuccessModal
      SuccessPath="contratacion"
      :open="successDialog"
      :propuesta="UserInfo.numero_propuesta"
      @ActionButton="handleContinue()"
      alert_message="<span class='p'>El cliente recibirá un <span class='bold'>e-mail</span> con los próximos pasos para la inspección a domicilio.</v-btn>" />
  </div>
</template>

<script>
import axios from "axios";
import LabelComponent from "@/components/Inputs/Label.vue";
import Warning from "@/components/User-Components/Contract/Warning.vue";
import DatePicker from "@/components/Inputs/DatePicker";
import TimePicker from "@/components/Inputs/TimePicker.vue";
import SuccessModal from "@/components/Modal/Modal-Success";
import TextFieldPhone from "@/components/Inputs/TextFieldPhone";
import { mapGetters } from "vuex";

export default {
  name: "HomeInspect",
  props: ["selectedInspectType", "selectedInspectCompany", "UserInfo"],
  data() {
    return {
      date: null,
      time: null,
      successDialog: false,
      loadingBtn: false,
      Address: {
        // text: `${this.benefict[0]?.direccion} ${this.benefict[0]?.numero}`,
        text: this.UserInfo.cotizacion.cliente,
        // ref: "origin",
        rules: [(v) => !!v || "Dirección del domicilio es requerido"],
      },
      Commune: {
        selected: "",
        rules: [(v) => !!v || "Comuna es requerida"],
      },
      Contact: {
        text: this.UserInfo.cotizacion.cliente,
        rules: [(v) => !!v || "Contacto es requerido"],
      },
      Phone: {
        text: this.UserInfo.cotizacion.telefono_cliente,
        tipo: "phone",
        rules: [(v) => !!v || "Teléfono es requerido"],
      },
    };
  },
  components: {
    LabelComponent,
    Warning,
    DatePicker,
    TimePicker,
    SuccessModal,
    TextFieldPhone,
  },
  methods: {
    handleBack() {
      this.$emit("back");
    },
    handleContinue() {
      this.$emit("next");
    },
    HandleInspectAtHome() {
      this.loadingBtn = true;
      if (this.$refs.form.validate()) {
        const user = this.UserInfo;
        const phone = this.Phone.text.replace(/[^A-Z0-9]/gi, "");
        axios({
          url: `propuestas/${user.numero_propuesta}/inspeccion`,
          method: "POST",
          data: {
            requiere_inspeccion: true,
            id_inspeccion: this.selectedInspectType._id,
            nombre_inspeccion: this.selectedInspectType.nombre,
            id_empresa: this.selectedInspectCompany.id_empresa,
            nombre_empresa: this.selectedInspectCompany.nombre_empresa,
            tipo_inspeccion: "IAD",
            direccion_domicilio: this.Address.text,
            contacto: this.Contact.text,
            telefono: phone,
          },
        })
          .then(() => {
            this.$refs.form.reset();
            this.successDialog = true;
            // this.$emit("next");
            // this.SuccessPath = "HomeInspection";
            // this.successDialog = true;
          })
          .finally(() => (this.loadingBtn = false));
      }
    },
  },
  mounted() {
    this.Address.text = this.benefict.direccion + ", " + this.benefict.numero;
    this.Commune.selected = this.benefict?.comuna ?? "";
  },
  computed: {
    ...mapGetters("Proposal", ["getCities"]),
    cities() {
      return this.getCities;
    },
    benefict() {
      return (
        this.UserInfo?.asegurado ??
        this.UserInfo?.beneficiarios?.[0] ??
        this.UserInfo?.cotizacion ??
        {}
      );
    },
  },
};
</script>
