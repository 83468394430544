var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-stepper',{attrs:{"tile":"","flat":"","elevation":"0"},model:{value:(_vm.selectedStep),callback:function ($$v) {_vm.selectedStep=$$v},expression:"selectedStep"}},[_c('v-stepper-header',[_vm._l((_vm.items),function(item,index){return [(item.active)?_c('v-stepper-step',{key:item.id,attrs:{"step":index + 1,"complete":_vm.selectedStep > index + 1}},[_c('div',{staticClass:"d-flex flex-column align-center",class:[
              _vm.selectedStep > index + 1
                ? 'primary--text'
                : '' || _vm.selectedStep === item.id
                ? 'primary--text'
                : 'unselected',
            ]},[_c('Icon',{attrs:{"name":item.icon,"size":"20px"}}),_c('span',{staticClass:"mt-2",class:[
                _vm.selectedStep > index + 1
                  ? 'primary--text'
                  : '' || _vm.selectedStep === item.id
                  ? 'primary--text bold'
                  : '',
              ]},[_vm._v(" "+_vm._s(item.label))])],1)]):_vm._e(),(index + 1 < _vm.items.length && item.active)?[_c('v-divider',{key:`divider_${item.id}`,class:[_vm.selectedStep > index + 1 && 'primary-border']})]:_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }