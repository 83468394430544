<template>
  <v-container>
    <div v-if="!paymentSelected">
      <v-row
        class="justify-center align-end"
        multi-line
        v-if="paymentList.length > 0">
        <v-col
          v-for="item in paymentList"
          :key="item._id"
          cols="6"
          md="3"
          xl="2"
          sm="4">
          <v-card
            @click="handlerClickPayment(item)"
            min-height="130"
            :class="[
              itemCode(item) === paymentsCodes[1] ? 'with-border' : 'light',
            ]"
            elevation="5"
            :tile="itemCode(item) === paymentsCodes[1]"
            :disabled="paymentCardDisabled(item)"
            :loading="item.loading">
            <!-- offer-card -->
            <!-- ETIQUETA RECOMENDADO -->
            <div
              class="text-center pa-1 info"
              v-if="itemCode(item) === paymentsCodes[1]">
              <span class="font-weight-bold">* RECOMENDADO</span>
            </div>
            <!-- <div class="offer-badge" v-if="itemCode(item) === paymentsCodes[1]">Recomendado</div> -->
            <!-- SOME CARDS PROPS -->
            <v-card-text>
              <div class="d-flex align-center">
                <v-icon x-large>{{
                  getPaymentMethod(itemCode(item))?.icon ??
                  "mdi-help-circle-outline"
                }}</v-icon>

                <span class="h4 bold primary--text ml-3">{{
                  item?.medio_descripcion ?? ""
                }}</span>
              </div>
              <span class="p">
                {{ getPaymentMethod(itemCode(item)).method }}
              </span>

              <p class="mb-0 mt-2">
                <b>{{ item.cuota }}</b> cuota{{
                  item.cuota == "1" ? "" : "s"
                }}
                de <br />
                <b
                  >{{ item.precio_local_periodo | moneyFormat }}
                  |
                  {{ item.precio_seguro_periodo | UFPrice }}</b
                >
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-layout justify-center class="mb-10 mt-15">
        <v-flex xs10 class="text-center">
          <v-btn
            color="primary"
            width="120"
            rounded
            outlined
            class="text-none"
            @click="goBack()"
            >Volver</v-btn
          >
        </v-flex>
      </v-layout>
    </div>
    <!-- FORMULARIOS DE MEDIOS DE PAGO -->
    <template v-else>
      <div v-if="paymentSelected?.codigo === paymentsCodes[1]">
        <PAT
          @back="back()"
          @next="next()"
          :payId="paymentSelected?.id_medio_de_pago ?? ''" />
      </div>
      <div v-else-if="paymentSelected?.codigo === paymentsCodes[2]">
        <PAC
          @back="back()"
          @next="next()"
          :payId="paymentSelected?.id_medio_de_pago ?? ''" />
      </div>
      <div v-else-if="paymentSelected?.codigo === paymentsCodes[3]">
        <Contado
          :payId="paymentSelected?.id_medio_de_pago ?? ''"
          :total_pesos="total_pesos"
          :total_uf="total_uf"
          @back="back()"
          @next="next()" />
      </div>
      <div v-else-if="paymentSelected?.codigo === paymentsCodes[6]">
        <MPAY2
          :payId="paymentSelected?.id_medion_de_pago ?? ''"
          :total_pesos="total_pesos"
          :total_uf="total_uf"
          :correo="payerData?.correo"
          :proposalNumber="proposalNumber"
          :error="{ value: mpayError, text: mpayErrorMessage }"
          :rut="payerData?.numero_documento"
          @back="back()" />
      </div>
      <div v-else-if="paymentSelected?.codigo === 'CV'">
        <CuentaVista @back="back()" @next="next()" />
      </div>
      <div
        v-else-if="paymentSelected?.codigo === paymentsCodes[5]"
        class="text-center">
        <template v-if="!mpayError">
          Estableciendo conexión con el servidor de MESOSPay <br /><br />
          <v-progress-circular
            indeterminate
            color="primary"
            size="100"></v-progress-circular>
          <br />
          <small>Porfavor espere...</small>
        </template>
        <template v-else>
          <span class="error--text">{{ mpayErrorMessage }}</span> <br />
          <small>Regresando a los medios de pago...</small>
        </template>
      </div>
      <div v-else class="text-center">
        <h4 class="title-5">
          ¡Ops! Aún estamos trabajando en ésta modalidad de pago.
        </h4>
        <p class="subtitle-1">
          Porfavor seleccione otro método de pago para continuar su proceso de
          cotización.
        </p>
        <div class="text-center">
          <v-btn
            color="primary"
            class="text-none"
            text
            @click="paymentSelected = null"
            ><v-icon left>mdi-arrow-left-bold</v-icon>Regresar</v-btn
          >
        </div>
      </div>
    </template>
  </v-container>
</template>
<script>
import PAT from "@/components/User-Components/Contract/Payer/PaymentMethods/PAT.vue";
import PAC from "@/components/User-Components/Contract/Payer/PaymentMethods/PAC.vue";
import Contado from "@/components/User-Components/Contract/Payer/PaymentMethods/Contado.vue";
import CuentaVista from "@/components/User-Components/Contract/Payer/PaymentMethods/CuentaVista.vue";
import MPAY2 from "@/components/User-Components/Contract/Payer/PaymentMethods/MPAY2.vue";
import { mapActions, mapGetters } from "vuex";
import {
  paymentMethods,
  paymentMethodsCodes,
} from "@/store/resources/payments.js";
import { cuotaCalc } from "@/store/resources/calc.js";
import { COUNTRIES } from "@/helpers/constants";

export default {
  name: "NewPaymentMethod",
  props: {
    selected: {
      type: String,
      default: "DEFAULT",
    },
    next: {
      type: Function,
      default: () => null,
    },
    proposalNumber: {
      type: [String, Number],
      require: true,
      description: "Numero de la propuesta",
    },
    payerData: {
      type: Object,
      default: () => {},
    },
    stepTitle: String,
  },
  components: {
    PAT,
    PAC,
    Contado,
    CuentaVista,
    MPAY2,
  },
  data() {
    return {
      countries: COUNTRIES,
      total_pesos: 0,
      total_uf: 0,
      paymentSelected: null,
      selectedLoading: false,
      openDialog: false,
      Mpay2: 2,
      rec: false,
      mpayError: false,
      mpayErrorMessage: "",
      cuotas: 0,
      paymentList: [],
    };
  },
  created() {
    this.paymentList = [...this.getTarifaPayments];
    if (this.selected) {
      this.paymentSelected = this.paymentList?.find(
        (e) =>
          e.codigo === this.paymentsCodes[6] ||
          e.codigo === this.paymentsCodes[7]
      );
    }
  },
  mounted() {
    const defaultTarifa = 0;
    this.cuotas = this.tarifa?.objeto_vigencia?.meses ?? defaultTarifa;

    if (this.shoppingCart?.oferta_seleccionada) {
      this.shoppingCart.oferta_seleccionada.map((it) => {
        this.total_pesos += it.moneda_local.precio;
        this.total_uf += it.moneda_seguro.precio;
      });
    } else {
      this.total_pesos = this.tarifa.moneda_local.precio;
      this.total_uf = this.tarifa.moneda_seguro.precio;
    }
    if (this.shoppingCart?.complementarios_seleccionados) {
      this.shoppingCart.complementarios_seleccionados.map((it) => {
        this.total_pesos += it.moneda_local.precio;
        this.total_uf += it.moneda_seguro.precio;
      });
    }
    if (this.shoppingCart?.adicionales_seleccionados) {
      this.shoppingCart.adicionales_seleccionados.map((it) => {
        this.total_pesos += it.moneda_local.precio;
        this.total_uf += it.moneda_seguro.precio;
      });
    }
  },
  computed: {
    ...mapGetters("Proposal", [
      "shoppingCart",
      "tarifa",
      "getTarifaPayments",
      "getMpay2Blocked",
    ]),

    payments() {
      return paymentMethods;
    },
    paymentsCodes: () => paymentMethodsCodes,
    country() {
      return this.$store.getters.getCountry;
    },
  },
  watch: {
    paymentSelected() {
      if (!this.paymentSelected || this.country === this.countries.PE) {
        this.$emit("stepTitle", "Seleccione el medio de pago");
      } else {
        this.$emit(
          "stepTitle",
          "Ingrese los datos del medio de pago seleccionado"
        );
      }
    },
  },
  methods: {
    ...mapActions("Proposal", ["sendMPay", "sendMPay2", "newClientPayment"]),

    itemCode(item = null) {
      return item.tipo_pago?.codigo || item?.codigo || "DEFAULT";
    },

    paymentCardDisabled(item = null) {
      return (
        ((this.itemCode(item) === paymentMethodsCodes[6] ||
          this.itemCode(item) === paymentMethodsCodes[7]) &&
          this.getMpay2Blocked) ||
        (this.selectedLoading && !item.loading)
      );
    },

    goBack() {
      this.$emit("back", true);
    },

    back() {
      this.openDialog = false;
      this.paymentSelected = null;
    },
    getCuota(pay) {
      return cuotaCalc(pay);
    },
    getPaymentMethod(code = "DEFAULT") {
      let payment;
      payment = this.payments.find((e) => e.code == code);
      if (!payment) {
        payment = this.payments.find((e) => e.code == "DEFAULT");
      }
      return payment;
    },
    async handlerClickPayment(item) {
      item.loading = true;
      this.selectedLoading = true;
      // Condiciones del medio de pago MPAY1
      const payload = {
        tiempo_pago: {
          codigo: item.tiempo_pago.codigo,
          descripcion: item.tiempo_pago.descripcion,
        },
        id_medio_de_pago: item.id_medio_de_pago,
        precio_seguro_periodo: item.precio_seguro_periodo,
        precio_local_periodo: item.precio_local_periodo,
        cuota: item.cuota,
        _id: item._id,
      };
      if (this.country === this.countries.PE) {
        await this.handlerSetAC(payload, item.codigo);
      } else {
        if (this.itemCode(item) == this.paymentsCodes[5]) {
          await this.handlerSetMPay1(item);
        } else if (
          this.itemCode(item) == this.paymentsCodes[6] ||
          this.itemCode(item) == this.paymentsCodes[7]
        ) {
          await this.handlerSetMpay2(payload);
        } else if (this.itemCode(item) == this.paymentsCodes[8]) {
          await this.handlerSetAC(payload);
        }
      }
      this.paymentSelected = item;
      item.loading = false;
      this.selectedLoading = false;
    },
    async handlerSetMPay1(item = null) {
      const payload = {
        header: this.proposalNumber,
        body: item,
      };
      try {
        await this.sendMPay(payload);
      } catch (error) {
        this.mpayError = true;
        this.mpayErrorMessage = error;
      }
      setTimeout(() => {
        this.goBack();
      }, 6000);
    },
    async handlerSetMpay2(payload = {}) {
      try {
        payload.medio_descripcion = payload.codigo = this.paymentsCodes[7];
        payload.id = this.proposalNumber;
        await this.sendMPay2(payload);
      } catch (error) {
        this.mpayError = true;
        this.mpayErrorMessage = error;
      }
    },
    async handlerSetAC(payload = {}, payment_method = {}) {
      try {
        payload.tipo_medio_pago =
          payload.codigo =
          payload.medio_descripcion =
            payment_method;
        payload.id = this.proposalNumber;
        await this.newClientPayment(payload);
        await this.next();
      } catch (error) {
        this.$store.commit("setSnackbar", {
          active: true,
          text: error,
          top: true,
          right: true,
          color: "error",
        });
      }
    },
    closeDialog() {
      this.openDialog = !this.openDialog;
    },
  },
};
</script>
