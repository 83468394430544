<template>
  <v-dialog persistent v-model="open" width="33%" attach="#main-container">
    <v-card class="br-6">
      <v-card-text class="pa-4 pb-6">
        <v-row>
          <v-col class="d-flex w-100 justify-space-between align-center">
            <span class="h5 semiBold">¡Oops!</span>
            <v-btn icon @click="close">
              <Icon name="close" size="25px" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex flex-column align-center mt-2">
              <Icon name="event_busy" size="80px" class="icon-error" />
              <Alert variant="error" class="w-100 mt-2">
                <template slot="content">
                  <div>
                    <span class="p"
                      >La
                      <span class="semiBold">fecha del documento</span>
                      ingresada es anterior a la permitida para considerar el
                      vehículo como cero kilómetro (48 horas de
                      antigüedad)</span
                    >
                  </div>
                </template>
              </Alert>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="light w-100 py-3" elevation="0">
              <v-card-text>
                <ul>
                  <li>
                    Por favor,
                    <span class="semiBold">revise la fecha del documento</span>
                    o bien
                    <span class="semiBold">adjunte otro documento</span> que
                    respalde la condición de vehículo nuevo y cuya fecha no
                    supere las 48 horas a la fecha actual (por ejemplo: factura,
                    guía de despacho, acta de entrega, etc.)
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-space-around">
              <v-btn
                @click="close"
                color="primary"
                width="160"
                rounded
                class="text-none"
                >Continuar</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";
import Alert from "@/components/Alert/Alert.vue";

export default {
  props: ["close"],
  data() {
    return {
      open: true,
      loader: 0,
      polizaSuccess: false,
      polizaError: false,
    };
  },
  components: {
    Icon,
    Alert,
  },
  watch: {
    loader: {
      handler(value) {
        if (value < 100) {
          setTimeout(() => {
            this.loader = this.loader + 20;
          }, 300);
        } else {
          this.polizaError = true;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
</style>
