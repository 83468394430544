<template>
  <v-card
    class="light h-100"
    :class="[disabled ? 'card-disabled' : 'global-cardStyle2 card-selectable']"
    :loading="loading"
    :elevation="disabled ? 0 : 5"
  >
    <div class="d-flex pa-3 flex-column">
      <div class="d-flex align-center">
        <Icon :name="getPayment(cardData.codigo).icon" size="25px" />
        <p class="mb-0 px-3 subtitle-1 semiBold primary--text">
          <span v-if="cardData.codigo == 'PAT'">
            {{
              cardData.numero_tarjeta
                ? cardData.numero_tarjeta[0] == 4
                  ? "Visa Débito"
                  : "MasterCard"
                : ""
            }}
          </span>

          <span v-if="cardData.codigo == 'PAC'"> Cuenta Bancaria </span>

          <span v-if="cardData.codigo == 'MPAY'"> M-PAY </span>
        </p>
      </div>

      <v-flex
        v-if="
          cardData.codigo === 'MPAY' &&
          disabled &&
          cardData.codigo_estado == 'PENDIENTE'
        "
        class="text-left"
      >
        <!-- BOTON RECARGAR ESTADO DE MPAY -->
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              small
              fab
              text
              @click="$emit('mpay-refresh', cardData)"
              :loading="mpayLoading"
              v-on="on"
              v-bind="attrs"
              name="boton_refrescar"
            >
              <v-icon>mdi-refresh-circle</v-icon>
            </v-btn>
          </template>
          <span>Consultar nuevamente el estado de la validación</span>
        </v-tooltip>

        <!-- BOTON RECHAZAR MPAY -->
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              small
              fab
              text
              @click="$emit('mpay-cancel', cardData)"
              :loading="loadingCancel"
              v-on="on"
              v-bind="attrs"
              name="boton_anular"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span>Anular la solicitud</span>
        </v-tooltip>
      </v-flex>
      <!-- <v-flex class="mt-3" xs12>
        <h3 class="global-titleStyle">
          {{ cardData.medio_pago_descripcion || cardData.codigo || "" }}
        </h3>
      </v-flex> -->
      <v-flex xs12>
        <p
          class="caption mb-0"
          v-if="
            cardData.codigo === 'CV' ||
            cardData.codigo === 'PAC' ||
            cardData.codigo == 'PAT'
          "
        >
          <span class="p semiBold">
            <span v-if="cardData.codigo != 'PAT'">Cuenta</span>
            <span v-else></span> Terminada en
            {{
              (cardData.codigo != "PAT"
                ? cardData.informacion.numero_cuenta_corriente
                : cardData.numero_tarjeta
              ).substring(
                (cardData.codigo != "PAT"
                  ? cardData.informacion.numero_cuenta_corriente
                  : cardData.numero_tarjeta
                ).length - 4
              )
            }}
          </span>
          <br />
          <span>Factura los dias {{ cardData.informacion.dia }}</span>
        </p>
      </v-flex>
      <span class="mt-2">{{
        cardData?.informacion?.banco_descripcion ?? ""
      }}</span>
    </div>
    <v-layout class="pa-3" wrap>
      <v-flex xs12>
        <h3
          class="global-thirdTitleStyle"
          v-if="cardData.codigo === 'PAC' || cardData.codigo === 'CV'"
        >
          {{ cardData.banco_descripcion }}
        </h3>
      </v-flex>
      <v-flex xs12 md12 v-if="cardData.codigo == 'MPAY'">
        <p class="caption mb-0 mt-2 text-right">Validación del pago:</p>
        <p class="text-right">
          <template v-if="!mpayLoading">
            <v-icon left :color="getPaymentStatus(cardData.codigo_estado).color"
              >mdi-circle</v-icon
            >
            {{ getPaymentStatus(cardData.codigo_estado).text }}
          </template>
          <template v-else>
            <v-progress-circular
              indeterminate
              color="grey"
            ></v-progress-circular>
          </template>
        </p>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";
import { paymentMethods } from "@/store/resources/payments.js";
export default {
  name: "PaymentCardComponent",
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
    cardHeight: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    mpayLoading: {
      type: Boolean,
      default: false,
    },
    loadingCancel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    payments() {
      return paymentMethods;
    },
  },
  methods: {
    getPayment(code) {
      let payment = this.payments.find((e) => e.code === code);
      if (!payment) {
        payment = {};
      }
      return payment;
    },
    getPaymentStatus(status) {
      let statusDescription;
      switch (status) {
        case "PENDIENTE":
          statusDescription = { text: "En proceso", color: "warning" };
          break;
        case "ACEPTADO":
          statusDescription = { text: "Aceptado", color: "success" };
          break;
        case "RECHAZADO":
          statusDescription = { text: "Rechazado", color: "error" };
          break;
        case "DADO_DE_BAJA":
          statusDescription = { text: "Cancelado", color: "grey" };
          break;

        default:
          statusDescription = "Desconocido";
          break;
      }
      return statusDescription;
    },
  },
  components: {
    Icon,
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
.card-selectable {
  cursor: pointer;
}
.card-disabled {
  border: solid 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.025);
}
</style>
