<template>
  <v-dialog persistent v-model="open" width="440px" attach="#main-container">
    <v-card class="br-6">
      <v-card-text>
        <v-row class="pt-5">
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <div>
              <h4 class="mb-2">Inicio de vigencia</h4>
            </div>
            <v-btn @click="toggle" icon>
              <Icon name="close" style="cursor: pointer" size="25px" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-4">
            <Alert variant="warning">
              <template slot="content">
                <span class="text"
                  >El inicio de vigencia ingresado <b>supera los xx días</b>.
                  Por favor, ingrese ingrese otra fecha a lo estipulado.</span
                >
              </template>
            </Alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn
              color="primary"
              rounded
              outlined
              width="140"
              @click="toggle"
              class="mr-2 text-none bg-white"
              >Volver</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// import Alert from "@/components/Alert/alert.vue";
import Icon from "@/components/Icon/Icon.vue";
import Alert from "@/components/Alert/Alert.vue";

export default {
  props: ["toggle", "open"],
  data() {
    return {};
  },
  components: {
    Alert,
    Icon,
  },
};
</script>

<style>
.text {
  font-size: 14px;
}
</style>
