<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <h6>Adjunte y complete los requisitos solicitados:</h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <Alert variant="info">
          <template slot="content"
            >Tipo de vehículo: <b>{{ newCar ? "NUEVO" : "USADO" }}</b></template
          >
        </Alert>
        <!-- <Alert :variant="needDocument ? 'warning' : 'success'">
          <template slot="content">
            <span v-if="needDocument"
              >El canal {{ channel }} solicita qué adjunte un
              <b>documento de venta o factura</b></span
            >
            <span v-else
              >El canal {{ channel }} no requiere
              <b>documento de venta</b></span
            >
          </template>
        </Alert>
        <Alert :variant="needInspection ? 'warning' : 'success'">
          <template slot="content">
            <span v-if="needInspection"
              >El canal {{ channel }} solicita <b>inspección</b></span
            >
            <span v-else
              >El canal {{ channel }} no requiere ningún tipo de
              <b>inspección</b></span
            >
          </template>
        </Alert> -->

        <!-- <v-slide-x-transition>
          <Alert v-if="oldDate" variant="error">
            <template slot="content">
              La <b>fecha de la factura</b> se encuentra desfasada, porfavor
              rellene la información adicional
            </template>
          </Alert>
        </v-slide-x-transition> -->
      </v-col>

      <v-col
        cols="12"
        md="8"
        offset-md="2"
        v-if="country === countries.PE && !newCar">
        <v-card class="light pa-10" outlined>
          <v-row>
            <v-col cols="12">
              <!-- Documento adjunto -->
              <LabelComponent text="Adjuntar tarjeta de propiedad" />
              <v-file-input
                outlined
                dense
                prepend-icon=""
                @change="handlerSelectFile($event, 'adicional')"
                accept="application/pdf"
                name="adj_doc_adicional">
                <template slot="append">
                  <Icon name="upload" size="22px" />
                </template>
              </v-file-input>
            </v-col>
            <v-col cols="12">
              <!-- Documento adjunto -->
              <LabelComponent text="Adjuntar comprobante de GPS" />
              <v-file-input
                outlined
                dense
                prepend-icon=""
                @change="handlerSelectFile($event, 'adicional')"
                accept="application/pdf"
                name="adj_doc_adicional">
                <template slot="append">
                  <Icon name="upload" size="22px" />
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        v-if="!newCar"
        :variant="needInspection ? 'warning' : 'success'">
        <Alert variant="warning">
          <template slot="content">
            <span v-if="country === countries.CL"
              >Todo vehículo usado requiere ser <b>inspeccionado</b> o que
              <b>adjunte un documento de entrega</b>
            </span>
            <span v-else
              >El canal requiere que el vehículo sea <b>inspeccionado</b> o que
              se
              <b>adjunte un documento de entrega</b>
            </span>
          </template>
        </Alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="light pa-10" outlined>
          <v-row v-if="!newCar && selected !== 'inspect'">
            <v-col>
              <p class="text-center">
                ¿Desea realizar inspección o adjuntar un documento de entrega?
              </p>
            </v-col>
          </v-row>
          <v-row class="pt-4 pb-8" v-if="selected !== 'inspect' && !newCar">
            <div class="w-100 d-flex justify-space-around align-center">
              <v-card
                class="hoverable typeCard light"
                @click="selectType('inspect')"
                :class="selected === 'inspect' ? 'selected' : ''">
                <v-card-text class="py-6">
                  <div class="d-flex flex-column align-center">
                    <Icon name="frame_inspect" size="20px" />
                    <span class="h4 primary--text mt-3">Inspeccionar</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                class="hoverable typeCard light"
                @click="selectType('document')"
                :class="selected === 'document' ? 'selected' : ''">
                <v-card-text class="py-6">
                  <div class="d-flex flex-column align-center">
                    <Icon name="upload" size="20px" />
                    <span class="h4 primary--text mt-3"
                      >Adjuntar documento</span
                    >
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-row>
          <v-row v-if="selected === '' && !newCar">
            <v-col>
              <div class="d-flex justify-center">
                <v-btn
                  color="primary"
                  class="mx-1 text-none"
                  outlined
                  rounded
                  @click="$emit('back')"
                  :disabled="loading"
                  width="140"
                  name="boton_volver">
                  Volver
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-fade-transition>
            <div
              v-if="
                country === countries.CL && (selected === 'document' || newCar)
              ">
              <DocumentForm
                :newCar="newCar"
                :proposalNumber="proposalNumber"
                :needInspection="needInspection"
                :needDocument="needDocument"
                :channel="channel"
                @next="next"
                @back="back" />
            </div>
            <div v-else-if="selected === 'inspect'">
              <Inspection
                @next="next"
                @back="back()"
                :inspect-companies="advanceInfo.inspecciones"
                :UserInfo="getProposalData"
                :needDocument="advanceInfo.requiere_documento_venta"
                :newCar="getProposalData.cotizacion.nuevo" />
            </div>
            <div
              v-else-if="
                country === countries.PE && (selected === 'document' || newCar)
              ">
              <PeruNewCarForm
                @next="next"
                @back="back()"
                :proposalNumber="proposalNumber"
                :needInspection="needInspection"
                :newCar="getProposalData.cotizacion.nuevo"
                :needDocument="needDocument" />
            </div>
          </v-fade-transition>
        </v-card>
      </v-col>
    </v-row>
    <ModalFecha v-if="showDateModal" :close="handleDateModal" />
  </v-container>
</template>

<script>
import Alert from "@/components/Alert/Alert.vue";
import Icon from "@/components/Icon/Icon.vue";
import ModalFecha from "@/components/Modal/Modal-fecha-factura.vue";
import DocumentForm from "@/components/User-Components/Contract/DocumentForm.vue";
import PeruNewCarForm from "@/components/User-Components/Contract/PeruDocumentForm.vue";
import Inspection from "@/components/User-Components/Contract/InspectionStep.vue";
import { mapGetters } from "vuex";
import { COUNTRIES } from "../../../helpers/constants";
import LabelComponent from "@/components/Inputs/Label.vue";

export default {
  name: "ContractRequirements",
  props: {
    proposalNumber: {
      type: [String, Number],
      default: "",
      description: "Numero de la propuesta",
    },
    newCar: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si el automovil es nuevo",
    },
    needInspection: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si la propuesta amerita inspeccion",
    },
    needDocument: {
      type: Boolean,
      default: false,
      description:
        "Indicador para definir si la propuesta amerita que el usuario adjunte un documento de factura",
    },
    channel: {
      type: String,
      default: "",
      description: "Nombre del canal",
    },
  },
  components: {
    Alert,
    Icon,
    ModalFecha,
    DocumentForm,
    Inspection,
    LabelComponent,
    PeruNewCarForm,
  },
  data: () => ({
    countries: COUNTRIES,
    selected: "",
    loading: false,
    showDateModal: false,
  }),
  computed: {
    ...mapGetters("Proposal", [
      "getProposalData",
      "getBeneficts",
      "getProposalGood",
      "getStep",
    ]),
    advanceInfo() {
      return this.$store.getters.getAdvanceInfo;
    },
    country() {
      return this.$store.getters.getCountry;
    },
  },
  methods: {
    next() {
      this.$emit("next");
    },
    back() {
      this.$emit("back");
    },
    selectType(selected) {
      this.selected = selected;
    },
  },
};
</script>

<style scoped>
.v-file-input {
  width: 400px !important; /* Adjust the width as per your requirement */
}
</style>
