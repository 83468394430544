<template>
  <div>
    <v-layout justify-center>
      <v-flex xs12 md8>
        <v-card class="light px-10 py-5" outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex align-center justify-center">
                <Icon name="payments" size="50px" />
                <span class="h4 bold primary--text ml-3">Contado</span>
              </v-col>
              <v-col
                cols="12"
                class="d-flex align-center flex-column justify-center mb-5 mt-2"
              >
                <span class="h5 semiBold">El monto total a pagar es:</span>
                <span class="h5 semiBold primary--text my-2">
                  {{ monto.pesos | moneyFormat }}
                  |
                  {{ monto.uf | UFPrice }}
                </span>
              </v-col>
              <div class="w-100">
                <v-divider></v-divider>
              </div>
              <div class="mb-4 w-100">
                <div class="d-flex justify-center text-center w-100">
                  <h5 class="text-center mt-3">Datos para la Transferencia</h5>
                </div>
                <div
                  class="d-flex w-100 justify-center mt-6"
                  v-for="(item, i) in transfDatos"
                  :key="i"
                >
                  <div class="d-flex w-70">
                    <div class="d-flex w-50">
                      <h5>{{ item.label }}:</h5>
                    </div>
                    <div class="d-flex w-50">
                      <span classs="p">{{ item.value }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-100">
                <v-divider></v-divider>
              </div>
            </v-row>
            <v-row>
              <div class="ml-2 mt-4">
                <span
                  >Para continuar con la operación, adjunte e ingrese los datos
                  del comprobante:</span
                >
                <v-row no-gutters class="mt-5">
                  <v-col cols="12">
                    <LabelComponent text="Comprobante de pago" />
                    <v-file-input
                      :accept="document.accept"
                      @change="handlerSelectDocument"
                      outlined
                      dense
                      small-chips
                      show-size
                      prepend-icon=""
                      truncate-length="50"
                      name="documento"
                    >
                      <template slot="append">
                        <Icon name="upload" size="22px" />
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <LabelComponent :text="documentNumber.label" />
                    <v-text-field
                      v-model="documentNumber.value"
                      outlined
                      dense
                      name="numero_doc"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-form ref="formPagoContado">
                  <v-btn
                    color="primary"
                    class="mx-2 text-none"
                    width="140"
                    outlined
                    rounded
                    @click="goBack"
                    name="boton_volver"
                    >Volver</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="mx-2 text-none ml-4"
                    width="140"
                    rounded
                    @click="goNext"
                    :loading="loading"
                    name="boton_continuar"
                    >Continuar</v-btn
                  >
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { fileToBase64 } from "@/services/file.js";
import { mapGetters } from "vuex";
import Icon from "@/components/Icon/Icon.vue";
import LabelComponent from "@/components/Inputs/Label.vue";

export default {
  props: {
    payId: {
      type: String,
      default: "",
      description: "Id del medio de pago seleccionado",
    },
    total_pesos: {
      type: Number,
      default: 0,
      description: "Precio total en Pesos",
    },
    total_uf: {
      type: Number,
      default: 0,
      description: "Precio total en UF",
    },
  },
  components: {
    Icon,
    LabelComponent,
  },
  data() {
    return {
      monto: {
        pesos: 0,
        uf: 0,
      },
      documentNumber: {
        value: "",
        label: "Número del comprobante",
        icon: "mdi-file",
        rules: [(v) => !!v || "Debe ingresar e número del comprobante."],
      },
      document: {
        date: "",
        accept: "application/pdf",
        label: "Comprobante de pago",
        hint: "Cargue su comprobante de pago para continuar con la operación",
        file: undefined,
        name: "",
        documentType: "CPC",
        descriptionType: "COMPROBANTE DE PAGO DE CONTADO",
        rules: [
          (v) => (!!v && v.size > 0) || "Debe cargar un documento válido.",
          (v) =>
            v?.type === "application/pdf" || "El archivo debe ser de tipo PDF",
        ],
      },
      loading: false,
    };
  },
  created() {
    this.monto.pesos =
      this.total_pesos *
      (this.tarifa && this.tarifa.objeto_vigencia
        ? this.tarifa.objeto_vigencia.meses
        : 1);
    this.monto.uf =
      this.total_uf *
      (this.tarifa && this.tarifa.objeto_vigencia
        ? this.tarifa.objeto_vigencia.meses
        : 1);
  },
  computed: {
    ...mapGetters("Proposal", ["getProposalData", "tarifa"]),
    transfDatos() {
      return [
        {
          label: "Razón Social",
          value: "Mesos Corredora de Seguros Ltda.",
        },
        {
          label: "RUT",
          value: "76.089.404-4",
        },
        {
          label: "Banco destino",
          value: "Banco de Chile",
        },
        {
          label: "Tipo de cuenta",
          value: "Cuenta Corriente",
        },
        {
          label: "Numero de cuenta",
          value: "1691852801",
        },
      ];
    },
  },
  methods: {
    async handlerSelectDocument(file) {
      this.document.file = await fileToBase64(file);
      this.document.name = file.name;
    },

    goBack() {
      this.$emit("back", true);
    },

    goNext() {
      if (this.$refs.formPagoContado.validate()) {
        this.loading = true;
        this.sendPayData()
          .then(() => this.$emit("next", true))
          .catch((e) => {
            const defaultMessage =
              "Ha ocurrido un error, no se ha logrado registrar el medio de pago.";
            const message =
              e.response.status == 500
                ? defaultMessage
                : e.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
                  defaultMessage;
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading = false));
      }
    },
    sendPayData(
      id_medio_pago = this.payId || "",
      proposal = this.getProposalData?.numero_propuesta ?? "",
      monto = this.monto || { uf: 0, pesos: 0 },
      comprobante = {
        fecha: this.document?.date ?? "",
        numero_comprobante: this.documentNumber?.value ?? "",
        documento: this.document?.file ?? "",
        nombre_archivo: this.document?.name ?? "",
        mime: this.document?.accept ?? "",
        tipo_documento: this.document?.documentType ?? "",
        tipo_descripcion: this.document?.descriptionType ?? "",
      }
    ) {
      return new Promise((resolve, reject) => {
        axios({
          url: `propuestas/${proposal}/pago/contado`,
          method: "POST",
          data: {
            id_medio_pago,
            monto,
            comprobante,
          },
        })
          .then(() => {
            resolve("ok");
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
</script>
