<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        v-model="computedTimeFormatted"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
      >
        <template slot="append">
          <Icon name="schedule" size="22px" />
        </template>
      </v-text-field>
    </template>
    <div class="container">
      <div class="d-flex">
        <div class="side d-flex flex-column">
          <v-list dense>
            <v-list-item-group v-model="selectedHour" color="primary">
              <v-list-item class="px-6"> 00 </v-list-item>
              <v-list-item v-for="index in 23" :key="index" class="px-6">
                {{ formatNumber(index) }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div class="side d-flex flex-column">
          <v-list dense>
            <v-list-item-group v-model="selectedMin" color="primary">
              <v-list-item class="px-6"> 00 </v-list-item>
              <v-list-item v-for="index in 59" :key="index" class="px-6">
                {{ formatNumber(index) }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
      <div class="pt-4 d-flex justify-end">
        <v-btn small color="primary" @click="menu = false" class="text-none"
          >Aceptar</v-btn
        >
      </div>
    </div>
  </v-menu>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";

export default {
  props: [],
  computed: {
    computedTimeFormatted() {
      return this.formatTime();
    },
  },
  data() {
    return {
      menu: false,
      selectedHour: 5,
      selectedMin: 0,
    };
  },

  methods: {
    formatNumber(number) {
      return number < 10 ? "0" + number : number;
    },
    formatTime() {
      return `${this.formatNumber(this.selectedHour)}:${this.formatNumber(
        this.selectedMin
      )} ${this.selectedHour >= 12 ? "pm" : "am"}`;
    },
  },
  created() {},
  components: {
    Icon,
  },
};
</script>

<style scoped>
.container {
  background-color: white !important;
  z-index: 100;
}
.side {
  max-height: 200px;
  overflow-y: scroll;
}
:deep(.v-list--dense) {
}
</style>
