<template>
  <v-dialog persistent v-model="open" width="476px" attach="#main-container">
    <v-card class="br-6">
      <v-card-text>
        <v-row class="pt-5">
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <div>
              <h4 class="mb-2">Interés asegurable</h4>
            </div>
            <v-btn @click="toggle" icon>
              <Icon name="close" style="cursor: pointer" size="25px" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-8">
            <Alert variant="warning">
              <template slot="content">
                <span
                  >Este producto sólo acepta la opción <b>propietario</b>. Por
                  favor, vuelva atrás y seleccione dicha opción.</span
                >
              </template>
            </Alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn
              color="primary"
              rounded
              outlined
              width="140"
              @click="toggle"
              class="mr-2 text-none bg-white"
              >Volver</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// import Alert from "@/components/Alert/alert.vue";
import Icon from "@/components/Icon/Icon.vue";
import Alert from "@/components/Alert/Alert.vue";

export default {
  props: ["toggle"],
  data() {
    return {
      open: true,
    };
  },
  components: {
    Alert,
    Icon,
  },
};
</script>
