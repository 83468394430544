<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h6>Complete los datos que faltan sobre los bienes del cliente</h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" offset="2" style="justify-content: center; display: flex">
        <v-card outlined class="" style="max-width: 665px">
          <div class="card-content">
            <v-card-text>
              <v-container>
                <div
                  class="borderBottom d-flex justify-space-between align-center mx-8">
                  <div class="d-flex">
                    <div class="pb-2 px-0" style="cursor: pointer">
                      <span class="py-2 px-5 h6 item-borderStyle primary--text">
                        {{ data?.categoria_descripcion ?? "--" }}
                      </span>
                    </div>
                  </div>
                </div>

                <v-fade-transition mode="out-in">
                  <car-form
                    v-if="formCode == 'automovil'"
                    ref="formComponent"
                    :proposal-data="data"
                    :venta-verde="ventaVerde" />
                  <home-form
                    v-else-if="formCode == 'hogar'"
                    ref="formComponent"
                    :proposal-data="data"
                    :venta-verde="ventaVerde" />
                </v-fade-transition>
              </v-container>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StepMixin from "@/Mixins/ContractStepMixin.js";
import CarForm from "@/components/User-Components/Contract/GoodStep/carForm.vue";
import HomeForm from "@/components/User-Components/Contract/GoodStep/homeForm.vue";
import { mapActions } from "vuex";

export default {
  name: "GoodStep",
  mixins: [StepMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
      description: "Datos de la propuesta",
    },
  },
  components: {
    CarForm,
    HomeForm,
  },
  computed: {
    formCode() {
      return this.data?.bien_asegurado?.formulario ?? "";
    },
    generalInfo() {
      return this.$store.getters.getGeneralInfo;
    },
    ventaVerde() {
      return this.generalInfo?.personalizacion_corporativa?.acceso_pantallas?.find(
        (val) => val.codigo === "VENTA_VERDE"
      )?.activo;
    },
  },
  methods: {
    ...mapActions("Proposal", ["updateGoodies"]),

    goNext() {
      return new Promise((resolve, reject) => {
        const formComponent = this.$refs.formComponent,
          form = formComponent?.$refs.form,
          formData = formComponent?.form;
        if (typeof form !== "undefined") {
          if (form?.validate()) {
            this.updateGoodies({ id: this.proposal, data: formData })
              .then(() => {
                resolve();
              })
              .catch((e) => {
                reject(e);
              });
          } else {
            reject();
          }
        } else {
          resolve();
        }
      });
    },
  },
};
</script>
<style scoped>
.borderBottom {
  border-bottom: solid 1px #cac4d0 !important;
}
.item-borderStyle {
  border-bottom: 3px solid #0077c8 !important;
}
</style>
