<template>
  <v-container>
    <v-row>
      <v-col
        cols="8"
        offset="2"
        :class="[alignTitleLeft ? 'text-start' : 'text-center']">
        <h6 v-if="payerStep === 0">Seleccione quién pagará el seguro</h6>
        <h6 v-if="payerStep === 1">Ingrese los datos del pagador</h6>
        <h6 v-if="payerStep === 2">Seleccione un medio de pago</h6>
        <h6 v-if="payerStep === 3">{{ titleStep3 }}</h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="payerStep === 0" class="d-flex flex-column">
          <div class="d-flex justify-center align-stretch flex-wrap">
            <div class="mw-30 ma-3">
              <PayerCard
                @click.native="handlerClickPayer(payerData)"
                :name="
                  payerData?.nombre
                    ? `${payerData.nombre} ${
                        payerData?.primer_apellido ?? ''
                      } ${payerData?.segundo_apellido ?? ''}`
                    : payerData?.razon_social ?? ''
                "
                :doc-number="payerData?.numero_documento"
                :loading="cardLoading"
                :next="next" />
            </div>
            <div class="mw-30 ma-3" v-if="country === countries.CL">
              <NewPayerCard v-on:click.native="show" />
            </div>
          </div>
          <div class="d-flex justify-center mt-10">
            <v-btn
              color="primary "
              rounded
              outlined
              width="130"
              class="text-none"
              @click="back()"
              >Volver</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>

    <div v-if="payerStep === 1">
      <Payer
        @next="nextPayerStep()"
        @back="backPayerStep()"
        :numero_propuesta="proposal"
        :rut_propuesta="payerData.numero_documento" />
    </div>
    <div v-if="payerStep === 2">
      <ClientPayment
        @back="goStart"
        @start="start"
        @cancelMpay="getPaymentMethods(payerData.numero_documento)"
        @mpay2="handlerSelectMpay2"
        :next="next"
        :blocked="getPMBlocked"
        :proposalNumber="proposal" />
    </div>
    <div v-if="payerStep === 3">
      <NewPayment
        ref="newPayments"
        @stepTitle="setTitle"
        @back="backPayerStep()"
        :next="next"
        :selected="newPaySelected"
        :proposalNumber="proposal"
        :payerData="data?.pagador" />
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PayerCard from "@/components/Cards/Payment/Payer-card.vue";
import ClientPayment from "@/components/User-Components/Contract/Payer/ClientPayments.vue";
import NewPayment from "@/components/User-Components/Contract/Payer/NewPaymentMethod.vue";
import Payer from "@/components/User-Components/Contract/Payer/Payer.vue";
import NewPayerCard from "@/components/Cards/Payment/New-Payer-card.vue";
import StepMixin from "@/Mixins/ContractStepMixin.js";
import { COUNTRIES } from "@/helpers/constants";

export default {
  mixins: [StepMixin],
  data() {
    return {
      countries: COUNTRIES,
      payerStep: 0,
      cardLoading: false,
      cancelMpay: false,
      titleStep3: "Seleccione el medio de pago",
      alignTitleLeft: false,
      newPaySelected: "",
    };
  },
  methods: {
    ...mapActions("Proposal", ["getPayments", "createPayer"]),
    setTitle(title) {
      if (title === "Seleccione el medio de pago") {
        this.alignTitleLeft = false;
      } else {
        this.alignTitleLeft = true;
      }
      this.titleStep3 = title;
    },
    show() {
      this.payerStep = 1;
    },
    start() {
      this.payerStep = 3;
    },
    goStart() {
      this.payerStep = 0;
    },
    nextPayerStep() {
      if (this.payerStep < 5) this.payerStep++;
      else this.payerStep = 1;
    },
    backPayerStep() {
      if (this.payerStep > 0) {
        // Condicion para recargar metodos de pagos
        if (this.payerStep == 3) {
          this.getPaymentMethods(this.payerData.numero_documento);
        } else {
          this.payerStep--;
        }
      }
    },
    handlerClickPayer(payer) {
      if (this.country === this.countries.PE) {
        this.payerStep = 3;
      } else {
        this.cardLoading = true;
        const payload = {
          numero_propuesta: this.proposal,
          payer_data: payer,
        };
        this.createPayer(payload)
          .then(() => {
            this.getPaymentMethods(payer.numero_documento);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.cardLoading = false;
          });
      }
    },
    getPaymentMethods(numero_documento) {
      this.getPayments({
        clientRut: numero_documento,
        proposalNumber: this.proposal,
      }).then(() => (this.payerStep = 2));
    },
    handlerSelectMpay2() {
      this.newPaySelected = "MPAY2";
      this.payerStep = 3;
    },
  },
  computed: {
    ...mapGetters("Proposal", [
      "getPaymentsMethods",
      "getStatus",
      "getPMBlocked",
      "getBeneficts",
    ]),

    payerData() {
      return this.data?.asegurado ?? {};
    },
    country() {
      return this.$store.getters.getCountry;
    },
  },
  components: {
    PayerCard,
    NewPayerCard,
    Payer,
    ClientPayment,
    NewPayment,
  },
};
</script>
<style scoped>
.mw-30 {
  min-width: 30%;
}
</style>
