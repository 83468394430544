<template>
  <div>
    <v-row no-gutters v-if="country === countries.PE">
      <v-col align="center">
        <Alert variant="info">
          <template slot="content">
            Recuerda que la <b>compañía llamará al cliente</b> para preguntarle
            más información sobre el medio de pago que seleccione (datos
            tarjeta, cuenta, etc)
          </template>
        </Alert>
      </v-col>
    </v-row>
    <v-layout justify-center class="mt-2">
      <v-flex xs12 v-if="PaymentMethods.length > 4">
        <!-- <v-card class="mt-4 d-flex" flat> -->
        <v-slide-group multiple show-arrows>
          <v-slide-item v-for="(item, i) in PaymentMethods" :key="i">
            <v-flex v-if="!item.type" xs2 class="mx-2">
              <Card
                :cardData="item"
                @mpay-refresh="handlerRefreshMpay"
                @mpay-cancel="handlerCancelMpay"
                @click.native="handlerClickPayment(item)"
                :loading="item.loading"
                :mpayLoading="mpayLoading"
                :loadingCancel="mpayLoadingCancel"
                :cardHeight="190"
                :disabled="
                  blocked ||
                  (item.codigo_estado && item.codigo_estado == 'RECHAZADO')
                " />
            </v-flex>

            <v-flex xs2 class="mx-2" v-else-if="!blocked">
              <NewPayment
                :isFirst="!(getPaymentsMethods.length > 1)"
                v-on:click.native="show" />
            </v-flex>
          </v-slide-item>
        </v-slide-group>
        <!-- </v-card> -->
      </v-flex>
      <v-flex xs12 v-else-if="PaymentMethods.length > 1">
        <v-layout justify-center>
          <template v-for="(item, index) in getPaymentsMethods">
            <v-flex v-if="!item.type" xs3 class="mx-2" :key="index">
              <Card
                :cardData="item"
                @mpay-refresh="handlerRefreshMpay"
                @mpay-cancel="handlerCancelMpay"
                @click.native="handlerClickPayment(item)"
                :loading="item.loading"
                :mpayLoading="mpayLoading"
                :loadingCancel="mpayLoadingCancel"
                :cardHeight="190"
                :disabled="
                  blocked ||
                  (item.codigo_estado && item.codigo_estado == 'RECHAZADO')
                " />
            </v-flex>
            <v-flex
              v-else-if="!blocked"
              xs3
              class="mx-2"
              :key="'nerpaument_' + index">
              <NewPayment
                :isFirst="!(getPaymentsMethods.length > 1)"
                v-on:click.native="show" />
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout justify-center wrap>
          <v-flex xs4 class="mx-2 justify-center">
            <NewPayment
              :isFirst="!(getPaymentsMethods.length > 1)"
              class="ml-7"
              v-on:click.native="show" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout justify-center class="mb-10 mt-15">
      <v-flex xs6 class="text-center">
        <v-btn
          color="primary"
          rounded
          outlined
          @click="goBack"
          width="140"
          class="mr-2 text-none"
          name="boton_volver"
          >Volver</v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Alert from "@/components/Alert/Alert.vue";
import Card from "@/components/Cards/Card-card.vue";
import NewPayment from "@/components/Cards/New-payment-card.vue";
import axios from "axios";
import { COUNTRY } from "@/helpers/constants";

export default {
  name: "ClientPaymentStep",
  props: ["start", "next", "blocked", "proposalNumber"],
  components: {
    Alert,
    Card,
    NewPayment,
  },
  data: () => ({
    countries: COUNTRY,
    mpayLoading: false,
    mpayLoadingCancel: false,
  }),
  methods: {
    ...mapMutations("Proposal", ["setPaymentStatus"]),
    ...mapActions("Payment", ["SEND_PAYMENT"]),

    goBack() {
      this.$emit("back", true);
    },

    show() {
      this.$emit("start", true);
    },
    handlerClickPayment(pay) {
      if (
        this.blocked ||
        (pay.codigo == "MPAY" && pay.codigo_estado !== "ACEPTADO")
      ) {
        return false;
      }
      pay.loading = true;
      const payload = {
        id: this.proposalNumber,
        data: pay,
      };
      this.SEND_PAYMENT(payload)
        .then(() => {
          this.goNext();
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (pay.loading = false));
    },
    handlerRefreshMpay() {
      this.mpayLoading = true;
      axios
        .get(`/medio-pagos/mpay/${this.proposalNumber}/estado`)
        .then((response) => {
          const result = response.data.data.result;
          const estado = result.estado;
          this.setPaymentStatus(estado);
        })
        .finally(() => (this.mpayLoading = false));
    },
    handlerCancelMpay() {
      this.mpayLoadingCancel = true;
      axios
        .delete(`/medio-pagos/mpay/${this.proposalNumber}/dar-de-baja`, {
          data: {
            motivo: "Dar de baja",
          },
        })
        .then((response) => {
          const result = response.data.data.result;
          const estado = result.estado;
          this.setPaymentStatus(estado);
          this.$emit("cancelMpay");
        })
        .finally(() => (this.mpayLoadingCancel = false));
    },
    goNext() {
      this.next();
    },
  },
  computed: {
    ...mapGetters("Proposal", ["getPaymentsMethods"]),
    PaymentMethods() {
      return this.getPaymentsMethods;
    },
    country() {
      return this.$store.getters.getCountry;
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/CSS/ServiceStyle.css";
.mw-30 {
  min-width: 30%;
}
</style>
