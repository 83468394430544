<template>
  <v-card
    class="h-100 cursor-pointer light card-selectable global-cardStyle2"
    elevation="5">
    <div class="d-flex pa-5 flex-column">
      <Icon name="add_circle" size="25px" />
      <span v-if="isFirst" class="h4 primary--text bold"
        >Agregar un nuevo medio de pago</span
      >
      <span v-else class="h4 primary--text bold"
        >Agregar un nuevo medio de pago</span
      >
    </div>
  </v-card>
</template>

<script>
import Icon from "@/components/Icon/Icon.vue";

export default {
  name: "RegisterPaymentButtonCard",
  props: {
    isFirst: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Icon,
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
</style>
