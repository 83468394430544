<template>
  <v-card
    class="light"
    elevation="5"
    style="cursor: pointer"
    :loading="loading"
  >
    <div class="d-flex align-center h-100 py-10 px-4">
      <Icon name="person" size="35px" />
      <div class="d-flex flex-column ml-3">
        <span class="h4 primary--text bold text-capitalize">{{
          name ?? ""
        }}</span>
        <span class="h4 primary--text">
          {{ (docNumber ?? "") | rutFormat }}</span
        >
      </div>
    </div>

    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>
  </v-card>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    docNumber: {
      type: String,
      default: "",
    },
  },
  components: {
    Icon,
  },
};
</script>
