<template>
  <v-layout wrap class="d-flex pb -6" justify-center>
    <!-- <v-flex xs12 class="d-flex justify-center mb-7">
      <div v-if="!isNew">
        <template v-if="!home">
          <h3 class="global-TextTitleBlue">
            Selecciona el método de inspección
          </h3>
          <p class="global-TextTitleBlack">
            acorde a las especificaciones de tu cliente.
          </p>
        </template>
      </div> -->
    <!-- If doesn't need an inspection text -->
    <!-- <template v-if="isNew == true" class="text-center">
        <h3 class="global-TextTitleBlue">
          Para crear la propuesta completa los datos
        </h3>
        <p class="global-TextTitleBlack">faltantes del cliente en cuestión.</p>
      </template> -->

    <!-- Text being shown on top  -->
    <!-- <div v-if="home == true" class="text-center">
        <h3 class="global-TextTitleBlue">
          Para la inspección <span v-if="!local">a domicilio </span>
          <span v-if="local">en local</span> completa los datos
        </h3>
        <p class="global-TextTitleBlack">faltantes del cliente en cuestión.</p>
      </div>
    </v-flex> -->

    <v-flex class="d-flex justify-center">
      <div v-if="!isNew" style="width: 100%">
        <!-- <v-row v-if="!needDocument">
          <v-col>
            <v-alert
              type="info"
              border="left"
              colored-border
              class="elevation-1"
            >
              Tipo de vehículo: <b>{{ newCar ? "NUEVO" : "USADO" }}</b>
            </v-alert>
          </v-col>
          <v-col>
            <v-alert
              type="success"
              border="left"
              colored-border
              class="elevation-1"
            >
              El canal {{ channel }} no requiere
              <b>documento de venta</b>
            </v-alert>
          </v-col>
        </v-row> -->
        <!-- Options for the page -->
        <div v-if="inspectCompanies.length > 0 || inspectCompaniesN.length > 0">
          <p class="">Seleccione una <b>compañia</b> para la inspección:</p>

          <div class="d-flex justify-space-between mt-4 containerCards">
            <v-card
              class="hoverable typeCard light"
              v-for="company in inspectCompaniesN"
              :key="company.id_empresa"
              @click="handlerChangeCompany(company)"
              :class="
                selectedInspectCompany?.id_empresa === company?.id_empresa
                  ? 'selected'
                  : ''
              ">
              <v-card-text
                class="py-8 d-flex align-center justify-center h-100">
                <div class="d-flex flex-column align-center">
                  <span class="h4 primary--text">
                    {{ company.nombre_empresa }}
                  </span>
                </div>
              </v-card-text>
            </v-card>
            <v-card
              class="hoverable typeCard light"
              v-if="country === countries.PE"
              key="companiadeseguros"
              @click="
                handlerChangeCompany({
                  id_empresa: 'companiadeseguros',
                  inspecciones: [],
                  id_inspeccion: [],
                  nombre_empresa: '',
                  codigo: '',
                })
              "
              :class="
                selectedInspectCompany?.id_empresa === 'companiadeseguros'
                  ? 'selected'
                  : ''
              ">
              <v-card-text class="py-8">
                <div class="d-flex flex-column align-center">
                  <span class="h4 primary--text">
                    Compañía <br />
                    de Seguros
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <!-- <v-chip-group column>
            <v-chip
              label
              large
              outlined
              color="primary"
              class="elevation-1"
              v-for="company in inspectCompanies"
              :key="company.id_empresa"
              @click="handlerChangeCompany(company)"
            >
            </v-chip>
          </v-chip-group> -->
          <v-slide-x-transition mode="out-in">
            <div v-if="inspecciones.length > 0" class="mt-8">
              <p class="py-4">Seleccione <b>un tipo</b> de inspección</p>
              <v-slide-x-transition
                group
                tag="div"
                class="d-flex justify-space-between w-100"
                mode="out-in">
                <div v-for="(item, i) in inspecciones" :key="i">
                  <PayerCard
                    v-if="!truck && item.tipo_inspeccion == 'AUTO'"
                    :userData="AutoInspection"
                    class="ma-4"
                    @click.native="
                      cleanTypes(),
                        (selectedInspectType = item),
                        handleAutoInspection()
                    "
                    :loading="loading"
                    :selected="
                      selectedInspectType?.tipo_inspeccion === 'AUTO'
                    " />
                  <PayerCard
                    v-if="item.tipo_inspeccion == 'DOMICILIO'"
                    :userData="Home"
                    class="ma-4"
                    @click.native="
                      cleanTypes(),
                        (selectedInspectType = item),
                        InspectLocal((item = 'domicilio'))
                    "
                    :selected="
                      selectedInspectType?.tipo_inspeccion === 'DOMICILIO'
                    " />

                  <PayerCard
                    v-if="item.tipo_inspeccion == 'LOCAL'"
                    :userData="Local"
                    class="ma-4"
                    @click.native="
                      cleanTypes(),
                        (selectedInspectType = item),
                        InspectLocal((item = 'local'))
                    "
                    :selected="
                      selectedInspectType?.tipo_inspeccion === 'LOCAL'
                    " />
                </div>
              </v-slide-x-transition>
            </div>
            <div
              class="pt-8 d-flex align-center"
              v-else-if="
                selectedInspectCompany?.id_empresa === 'companiadeseguros'
              ">
              <Icon name="info" size="20px" class="info-text" />
              <span class="ml-2 font-14">
                <b>La compañía de seguros</b> se encargará de
                <b>realizar la inspección.</b>
              </span>
            </div>
          </v-slide-x-transition>
        </div>

        <div v-else>
          <div v-if="loadingCmp" class="d-flex flex-column align-center mt-2">
            <v-progress-circular
              :rotate="-60"
              :size="80"
              :width="10"
              indeterminate
              class="primary--text">
            </v-progress-circular>
            <span class="p mt-2"> Estamos generando su póliza</span>
          </div>
          <div v-else class="text-center">
            <p class="">No hay compañias para la inspección</p>
            <v-btn
              color="primary"
              rounded
              @click="refCompanies()"
              :loading="loadingCmp"
              class="mt-8 mx-1 text-none"
              width="140"
              name="boton_refrescar"
              >Refrescar</v-btn
            >
          </div>
        </div>

        <Home
          v-if="
            home && selectedInspectCompany?.id_empresa !== 'companiadeseguros'
          "
          @back="handleBack"
          @next="handleNext"
          :selectedInspectType="selectedInspectType"
          :selectedInspectCompany="selectedInspectCompany"
          :UserInfo="UserInfo" />
        <Local
          v-if="
            local && selectedInspectCompany?.id_empresa !== 'companiadeseguros'
          "
          @back="handleBack"
          @next="handleNext"
          :selectedInspectType="selectedInspectType"
          :selectedInspectCompany="selectedInspectCompany"
          :UserInfo="UserInfo" />
        <div
          v-if="
            auto && selectedInspectCompany?.id_empresa !== 'companiadeseguros'
          ">
          <div class="my-6">
            <Warning />
          </div>
          <div class="mt-8 text-center">
            <v-btn
              color="primary"
              class="mx-1 text-none"
              outlined
              rounded
              @click="handleBack()"
              :disabled="loading"
              width="140"
              name="boton_volver"
              >Volver</v-btn
            >

            <v-btn
              v-if="auto"
              color="primary"
              rounded
              @click="HandleAutoInspect()"
              :loading="loadingBtn"
              class="mx-1 text-none"
              width="140"
              name="boton_continuar_local"
              >Continuar</v-btn
            >
          </div>
        </div>
        <div v-if="selectedInspectCompany?.id_empresa === 'companiadeseguros'">
          <div class="mt-7 d-flex justify-center">
            <v-btn
              color="primary"
              rounded
              outlined
              width="140"
              :loading="loadingBtn"
              class="text-none"
              @click="goBack()"
              name="boton_continuar"
              >Volver</v-btn
            >
            <v-btn
              @click="handleNext()"
              color="primary"
              rounded
              class="ml-4 text-none"
              name="boton_continuar_local"
              width="140"
              >Aceptar</v-btn
            >
          </div>
        </div>
      </div>

      <!-- if item is new  -->
      <div v-if="isNew">
        <v-fade-transition>
          <div
            class="global-NotificationCard grey lighten-3 align-center d-flex"
            v-if="alert">
            <span class="global-notificationtxt">
              No requiere inspección, tiene que adjuntar la factura del vehículo
              y
              <br />
              en el caso de necesitarlo el acta de entrega o guía de
              despacho.</span
            >
            <v-btn
              small
              class="ml-7 blue--text"
              @click="alert = false"
              text
              name="boton_entendido"
              >Entendido</v-btn
            >
          </div>
        </v-fade-transition>

        <div class="global-NotificationCard d-flex">
          <v-flex xs6 class="d-flex align-center">
            <CheckBox
              color="#0077C8"
              label="Factura más de 48 hs."
              class="mb-5 global-checkbox"
              name="factura_mas_48_hrs" />
            <!-- :check="ventaVerde" -->
            <!-- @input="ventaVerde = $event" -->
          </v-flex>
          <v-flex xs6 class="d-flex justify-end">
            <label for="file_input_id" class="files">
              <v-icon size="22px" color="#0077C8">mdi-paperclip</v-icon
              ><span class="global-input">Adjuntar</span></label
            >
            <input
              type="file"
              accept="application/pdf"
              ref="input"
              @change="onFileSelect"
              id="file_input_id"
              name="input_archivo" />
          </v-flex>
        </div>

        <SimpleTable
          :headers="headers"
          :items="documents"
          :DownloadButton="downDownloadbtn"
          :ShowButton="showbtn" />
        <div class="mt-7">
          <v-btn
            color="primary"
            rounded
            outlined
            width="140"
            :loading="loadingBtn"
            class="text-none"
            @click="goBack()"
            name="boton_continuar"
            >Volver</v-btn
          >
          <v-btn
            @click="HandleNoInspectionNeeded()"
            color="primary"
            rounded
            :loading="loading"
            class="mx-1 text-none"
            name="boton_continuar_local"
            :disabled="!date"
            width="140"
            >Aceptar</v-btn
          >
        </div>
      </div>
    </v-flex>
    <SuccessModal
      SuccessPath="contratacion"
      :open="successDialog"
      :propuesta="UserInfo.numero_propuesta"
      @ActionButton="handleNext()"
      alert_message="<span class='p'>El cliente recibirá un <span class='bold'>e-mail</span> para continuar con el proceso de <span class='bold'>autoinspección.</span></v-btn>" />
    <PolizaModal :open="PolizaModal" @handleBtnClick="handleModalPoliza" />
    <ModalFile v-if="openModal" />
  </v-layout>
</template>
<script>
import PayerCard from "@/components/Cards/Contract-card";
import SuccessModal from "@/components/Modal/Modal-Success";
import axios from "axios";
import PolizaModal from "@/components/Modal/Modal-Poliza";
import { mapActions, mapGetters } from "vuex";
import ModalFile from "@/components/Modal/Modal-show-file.vue";
import Home from "@/components/User-Components/Contract/Home.vue";
import Local from "@/components/User-Components/Contract/Local.vue";
import Warning from "@/components/User-Components/Contract/Warning.vue";
import Icon from "@/components/Icon/Icon.vue";
import { COUNTRIES } from "@/helpers/constants";

export default {
  name: "ContractInspection",
  props: {
    UserInfo: {
      type: [Array, Object],
      require: true,
      file: null,
    },
    inspectCompanies: {
      type: Array,
      default: () => [],
      description:
        "Listado de las companias para inspeccion (contiene los tipos de inspeccion)",
    },
    needDocument: {
      type: Boolean,
      default: false,
    },
    newCar: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: String,
      default: "",
    },
  },
  components: {
    PayerCard,
    PolizaModal,
    SuccessModal,
    ModalFile,
    Home,
    Local,
    Warning,
    Icon,
  },
  data() {
    return {
      countries: COUNTRIES,
      payerStep: 0,
      successDialog: false,
      PolizaModal: false,
      loading: false,
      loadingCmp: false,
      isTruck: false,
      isNew: false,
      alert: true,
      isHome: true,
      home: false,
      local: false,
      auto: false,
      loadingBtn: false,
      files: [],
      SuccessPath: "",
      inspectCompaniesNew: [],
      inspecciones: [],
      selectedInspectCompany: null,
      selectedInspectType: null,

      AutoInspection: {
        nombre: "Autoinspección",
        numero_documento: "",
        icon: "person",
      },

      Local: {
        nombre: "Inspección en local",
        numero_documento: "",
        icon: "store",
      },

      Home: {
        nombre: "Inspección a domicilio",
        numero_documento: "",
        icon: "home",
      },

      Address: {
        label: "",
        text: "",
        ref: "origin",
        rules: [(v) => !!v || "Dirección del domicilio es requerido"],
      },

      Contact: {
        label: "Contacto",
        text: "",

        rules: [(v) => !!v || "Contacto es requerido"],
      },

      Phone: {
        label: "Teléfono",
        text: "",
        tipo: "phone",
        rules: [(v) => !!v || "Teléfono es requerido"],
      },

      headers: [
        {
          text: "Nombre",
          value: "archivo_descripcion",
        },
        {
          text: "Tipo",
          value: "documento_descripcion",
        },
        {
          text: "Tamaño",
          value: "size_archivo",
        },
        {
          text: "Fecha",
          value: "fecha_creacion_firma",
        },
        {
          text: "Acción",
          value: "ci_action",
          align: "center",
        },
      ],

      documents: [],
    };
  },
  mounted() {
    const user = this.UserInfo;

    if (user.cotizacion.producto === "3" && user.cotizacion.nuevo) {
      this.isTruck = true;
      this.isNew = true;
    }
    if (user.cotizacion.nuevo && user.cotizacion.producto !== "3") {
      this.isNew = true;
      this.isTruck = false;
    }
  },
  methods: {
    ...mapActions("Payment", ["OpenShowModal"]),
    ...mapActions("Proposal", ["refreshCompanies"]),
    cleanTypes() {
      this.home = false;
      this.local = false;
      this.auto = false;
    },
    handleBack() {
      this.$emit("back");
    },
    handleNext() {
      this.$emit("next");
    },
    goBack() {
      this.isNew = false;
      this.isTruck = false;
      this.$emit("back", true);
    },
    showbtn(item) {
      this.OpenShowModal({ item: item });
    },

    downDownloadbtn(item) {
      if (item.archivo) {
        const blob = new Blob([item.archivo], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = item.archivo_descripcion;
        link.click();
      }
    },

    InspectLocal(item) {
      this.isHome = false;

      if (item === "local") {
        this.local = true;
        this.Address.label = "Dirección del local";
      }
      if (item === "domicilio") {
        this.home = true;
        this.local = false;
        this.Address.label = "Dirección del domicilio";
      }
    },

    // Upload documents when no inspecction is needed
    async HandleNoInspectionNeeded() {
      console.log("--- No Inspection Needed ---");
      this.loadingBtn = true;
      if (this.documents.length > 0) {
        await this.documents.map((it, index) => {
          const user = this.UserInfo;
          axios({
            url: `propuestas/${user.numero_propuesta}/inspeccion`,
            method: "POST",
            data: {
              tipo_inspeccion: "NRI",
              tipo_documento: it.documento_descripcion,
              nombre_documento: it.archivo.name,
              extension: it.archivo.type,
              archivo: this.files[index],
            },
          }).then(() => {
            if (index === this.documents.length - 1) {
              // this.SuccessPath = "contratacion";
              // this.successDialog = true;
              this.$emit("next");
            }
          });
        });
      } else {
        // this.SuccessPath = "contratacion";
        // this.successDialog = true;
        this.$emit("next");
      }
      this.loadingBtn = false;
    },

    handleAutoInspection() {
      this.auto = true;
    },
    // Check if inspeccion is needed, if yes post it
    HandleAutoInspect() {
      const user = this.UserInfo;

      if (user.nuevo) {
        this.isNew = true;
      } else {
        this.loading = true;

        axios({
          url: `propuestas/${user.numero_propuesta}/inspeccion`,
          method: "POST",
          data: {
            requiere_inspeccion: true,
            id_inspeccion:
              this.selectedInspectType._id != null
                ? this.selectedInspectType._id
                : this.selectedInspectType.id,
            nombre_inspeccion:
              this.selectedInspectType.nombre != null
                ? this.selectedInspectType.nombre
                : this.selectedInspectType.descripcion,
            id_empresa: this.selectedInspectCompany.id_empresa,
            nombre_empresa: this.selectedInspectCompany.nombre_empresa,
            tipo_inspeccion: "AUI",
            tipo_documento: user.cotizacion.usuario,
            numero_documento: user.numero_documento,
            patente: user.cotizacion.patente,
          },
        })
          .then(() => {
            this.successDialog = true;
            // this.$emit("next");
            // this.SuccessPath = "contratacion";
            // this.successDialog = true;
          })
          .finally(() => (this.loading = false));
      }
    },

    // On file selected when inspeccion is no needed
    async onFileSelect(e) {
      await this.toBase64(e.target.files[0]);

      const NewFile = [
        {
          archivo_descripcion: e.target.files[0].name,
          documento_descripcion: "Propuesta",
          size_archivo: e.target.files[0].size,
          fecha_creacion_firma: new Date(),
          archivo: e.target.files[0],
        },
      ];

      const newfile = Object.assign({}, NewFile[0]);

      this.documents.push(newfile);
    },

    toBase64(file) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          var fileData = reader.result.toString().split(",");

          this.files.push(fileData[1]);

          resolve(this.files);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },

    handleModalPoliza(value) {
      const user = this.UserInfo;

      if (value === "close") {
        this.PolizaModal = false;
      } else {
        axios({
          url: `propuestas/${user.numero_propuesta}/cancelar-poliza`,
          method: "POST",
          data: {
            motivo: value,
            fecha_desde: "2021-01-2",
          },
        })
          .then(() => {
            this.PolizaModal = false;
            // this.SuccessPath = "HomeInspection";
            // this.successDialog = true;
          })
          .catch(() => {});
      }
    },

    handlerChangeCompany(item) {
      this.inspecciones = item.inspecciones;
      this.selectedInspectCompany = item;
    },

    HandleCloseDialog() {
      this.successDialog = false;
      this.SuccessPath = "";
      this.$emit("next");
    },
    refCompanies() {
      this.loadingCmp = true;
      this.refreshCompanies()
        .then((val) => {
          this.inspectCompaniesNew = val;
        })
        .catch((e) => {
          const message = e.response.data.data.lista_mensaje[0].mensaje;
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loadingCmp = false;
        });
    },
  },
  computed: {
    ...mapGetters("Payment", ["getShowModal"]),
    ...mapGetters("Users", ["getTituloColor", "getUserInfo"]),
    ...mapGetters("Proposal", ["getProposalData"]),
    advanceInfo() {
      return this.$store.getters.getAdvanceInfo;
    },
    openModal() {
      return this.getShowModal;
    },
    titleColor() {
      if (this.getTituloColor != null) {
        return this.getTituloColor;
      } else {
        return "#0077c8";
      }
    },
    newVehicle() {
      return this.isNew;
    },

    truck() {
      return this.isTruck;
    },
    country() {
      return this.$store.getters.getCountry;
    },

    inspectCompaniesN() {
      const ins =
        this.inspectCompanies.length > 0
          ? this.inspectCompanies
          : this.inspectCompaniesNew;
      return ins;
    },
  },
};
</script>
<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";

#file_input_id {
  display: none;
}

.containerCards {
  align-items: stretch;
}
.typeCard {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}
</style>
