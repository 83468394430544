<template>
  <v-form ref="form" class="px-2 pb-10">
    <v-row class="pt-10">
      <v-col cols="12" md="6" class="px-6" style="max-height: 75px">
        <LabelComponent text="Marca*" />
        <v-text-field
          name="marca"
          outlined
          dense
          disabled
          :value="goodData.marca_descripcion"></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="px-6" style="max-height: 75px">
        <LabelComponent text="Modelo*" />
        <v-text-field
          :value="goodData.modelo_descripcion"
          disabled
          outlined
          dense
          name="modelo"></v-text-field>
      </v-col>

      <!-- ANIO -->
      <v-col cols="12" md="6" class="px-6" style="max-height: 75px">
        <LabelComponent text="Año*" />
        <v-text-field
          :value="goodData.anio"
          disabled
          outlined
          dense
          name="goodYear"></v-text-field>
      </v-col>
      <!-- PATENTE -->
      <v-col cols="12" md="6" class="px-6" style="max-height: 75px">
        <LabelComponent :text="`${$t('patente')}*`" />
        <v-text-field
          :value="goodData.patente"
          disabled
          outlined
          dense
          name="patente"></v-text-field>
      </v-col>

      <!-- NUMERO DE MOTOR -->
      <v-col cols="12" md="6" class="px-6" style="max-height: 75px">
        <LabelComponent text="Número de motor*" />
        <v-text-field
          placeholder="Escribe el número del motor..."
          v-model="form.numero_motor"
          :rules="form.venta === 'V' ? [] : [rules.required, rules.min8]"
          name="numero_motor"
          :onkeypress="`return ${alphaNum()}`"
          outlined
          dense
          @keyup="form.numero_motor = form.numero_motor.toUpperCase()"
          maxlength="20"></v-text-field>
      </v-col>
      <!-- NUMERO DE CHASIS -->
      <v-col cols="12" md="6" class="px-6" style="max-height: 75px">
        <LabelComponent text="Número de chasis*" />
        <v-text-field
          placeholder="Escribe el número del chasis..."
          v-model="form.numero_chasis"
          :rules="form.venta === 'V' ? [] : [rules.required, rules.min17]"
          name="numero_chasis"
          :onkeypress="`return ${alphaNum()}`"
          outlined
          dense
          @keyup="form.numero_chasis = form.numero_chasis.toUpperCase()"
          maxlength="20"></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="px-6"
        v-if="country === countries.PE"
        style="max-height: 75px">
        <LabelComponent text="Inicio de vigencia*" />

        <!-- <LabelComponent text="Fecha de nacimiento*" /> -->
        <v-text-field
          v-model="form.inicio_vigencia"
          :rules="[rules.required, rules.vigencia]"
          placeholder="DD/MM/AAAA"
          v-mask="dateMask"
          outlined
          @blur="checkModalInicioVigencia(form.inicio_vigencia)"
          dense
          name="inicio_vigencia"></v-text-field>
      </v-col>

      <!-- // COLOR FIELD -->
      <v-col
        cols="12"
        md="6"
        class="px-6"
        name="flex_name"
        style="max-height: 75px">
        <LabelComponent text="Color*" />
        <v-select
          :items="getColorsData"
          v-model="form.color"
          placeholder="Seleccione un color"
          outlined
          :rules="[rules.required]"
          dense
          item-text="descripcion"
          item-value="codigo"
          hide-details
          append-icon="mdi-chevron-down"
          mandatory
          name="color"></v-select>
      </v-col>
      <v-col xs6 class="ml-4 align-self-center">
        <v-checkbox
          v-if="ventaVerde"
          label="Venta verde"
          v-model="form.venta"
          :true-value="'V'"
          :false-value="'N'"></v-checkbox>
      </v-col>
    </v-row>
    <ModalInicioVigencia :open="openModalVigencia" :toggle="toggleModal" />
  </v-form>
</template>

<script>
import LabelComponent from "@/components/Inputs/Label.vue";
import { validateMaxLength } from "@/store/resources/validate.js";
import { alphaNumericKeys } from "@/store/resources/calc.js";
import { mapActions, mapGetters } from "vuex";
import { COUNTRIES } from "@/helpers/constants";
import ModalInicioVigencia from "@/components/Modal/Modal-inicio-vigencia";
import { dateMask } from "@/helpers/mask.js";
import moment from "moment-timezone";

export default {
  name: "GoodCarForm",
  props: {
    proposalData: {
      type: Object,
      default: () => {},
    },
    ventaVerde: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LabelComponent,
    ModalInicioVigencia,
  },
  data() {
    var self = this;
    return {
      diferenciaDias: 1,
      countries: COUNTRIES,
      openModalVigencia: false,
      loadingColors: false,
      date: null,
      form: {
        marca: undefined,
        modelo: undefined,
        anio: "",
        patente: "",
        fecha_factura: undefined,
        interes_asegurable: false,
        numero_factura: "",
        numero_motor: "",
        numero_chasis: "",
        color: "",
        venta: "",
        inicio_vigencia: null,
      },
      self: this,
      rules: {
        required: (value) => !!value || "Campo requerido.",
        min8: (value) => validateMaxLength(value, 8),
        min17: (value) => validateMaxLength(value, 17),
        vigencia: (value) =>
          self.getDayDifference(value) < this.diferenciaDias ||
          "Ingrese otra fecha",
      },
    };
  },
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Proposal", ["getColorsData"]),
    dateMask: () => dateMask,

    goodData() {
      const cotizacion = this.proposalData?.cotizacion;
      const datosBien = this.proposalData?.datos_bien;
      return {
        marca: cotizacion?.obj_marca?.marca ?? "",
        marca_descripcion: cotizacion?.obj_marca?.marca_descripcion ?? "",
        modelo: cotizacion?.obj_modelo?.modelo ?? "",
        modelo_descripcion: cotizacion?.obj_modelo?.modelo_descripcion ?? "",
        anio: cotizacion?.anio ?? "",
        patente: cotizacion?.patente ?? "",
        color: datosBien?.color ?? "",
        venta: datosBien?.venta ?? "N",
        fecha_factura: datosBien?.fecha_factura ?? "",
        numero_motor: datosBien?.numero_motor ?? "",
        numero_chasis: datosBien?.numero_chasis ?? "",
        inicio_vigencia: datosBien?.inicio_vigencia ?? null,
        interes_asegurable:
          this.proposalData?.tarifa_seleccionada?.interes_asegurable ?? false,
      };
    },
    alphaNum() {
      return alphaNumericKeys;
    },
    country() {
      return this.$store.getters.getCountry;
    },
  },

  methods: {
    ...mapActions("Proposal", ["getColors"]),
    toggleModal() {
      this.openModalVigencia = !this.openModalVigencia;
    },
    setup() {
      // == Obtener listado de colores
      this.setColors();
      // == Rellenar datos del formulario
      this.setFormData();
    },
    setFormData() {
      this.form.marca = this.goodData.marca;
      this.form.modelo = this.goodData.modelo;
      this.form.anio = this.goodData.anio;
      this.form.patente = this.goodData.patente;
      this.form.fecha_factura = this.goodData.fecha_factura;
      this.form.interes_asegurable = this.goodData.interes_asegurable;
      this.form.numero_factura = this.goodData.numero_factura;
      this.form.numero_motor = this.goodData.numero_motor;
      this.form.numero_chasis = this.goodData.numero_chasis;
      this.form.color = this.goodData.color;
      this.form.venta = this.goodData.venta;
      this.form.inicio_vigencia = this.goodData.inicio_vigencia;
    },
    setColors() {
      this.loadingColors = true;
      this.getColors().finally(() => {
        this.loadingColors = false;
      });
    },
    getDayDifference(value) {
      const fechaEspecifica = moment(value, "DD/MM/YYYY");
      const fechaActual = moment();
      const diferenciaDias = fechaEspecifica.diff(fechaActual, "days");
      return diferenciaDias;
    },
    checkModalInicioVigencia(value) {
      const diferenciaDias = this.getDayDifference(value);
      if (diferenciaDias > this.diferenciaDias) {
        this.openModalVigencia = true;
      }
    },
  },
};
</script>
