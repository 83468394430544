<template>
  <v-card class="light h-100" elevation="5" style="cursor: pointer">
    <div
      class="d-flex align-center primary--text justify-center h-100 py-10 px-4"
    >
      <Icon name="add_circle" size="25px" />
      <span class="h4 primary--text bold ml-3">Otro</span>
    </div>
  </v-card>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";

export default {
  components: {
    Icon,
  },
};
</script>
