<template>
  <v-dialog persistent v-model="open" width="35%" attach="#main-container">
    <v-card class="br-6">
      <v-card-text class="pa-4 pb-6">
        <v-row>
          <v-col class="d-flex w-100 justify-space-between align-center">
            <span class="h5 semiBold">Editar email del cliente</span>
            <v-btn icon @click="toggle">
              <Icon name="close" size="25px" />
            </v-btn>
          </v-col>
          <v-col cols="12">
            <LabelComponent text="Email*" />
            <inputText
              placeholder="Email"
              :model="correo"
              color="#0077C8"
              @input="correo = $event"
              :rules="[rules.required, rules.email]"
              :hide="false"
              name="ResendEmailModal" />
          </v-col>
          <v-col>
            <Alert variant="warning">
              <template slot="content">
                <span>
                  Importante: <br />
                  El nuevo email ingresado será el que aparecerá en la póliza y
                  se actualizará en los datos del cliente.
                </span>
              </template>
            </Alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-space-around">
              <v-btn
                rounded
                outlined
                color="primary "
                width="130"
                class="text-none"
                @click="toggle"
                >Cancelar</v-btn
              >
              <v-btn
                rounded
                @click="changeEmailAndResend"
                color="primary"
                width="280"
                class="text-none"
                :loading="loading"
                >Reenviar Token a nuevo email</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import inputText from "@/components/Inputs/Input";
import Icon from "@/components/Icon/Icon.vue";
import Alert from "@/components/Alert/Alert.vue";
import LabelComponent from "@/components/Inputs/Label.vue";
import { validarEmail } from "@/store/resources/validate.js";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  props: ["toggle", "clientEmail", "ProposalNumber"],
  data() {
    return {
      open: true,
      correo: "",
      loading: false,
      rules: {
        required: (value) => !!value || "Campo requerido.",
        email: (value) => validarEmail(value) || "Correo invalido.",
      },
    };
  },
  components: {
    Icon,
    LabelComponent,
    inputText,
    Alert,
  },
  mounted() {
    this.correo = this.clientEmail;
  },
  methods: {
    ...mapActions("Proposal", ["updateProposalEmail"]),
    changeEmailAndResend(v) {
      this.loading = true;
      this.updateProposalEmail({
        id: this.ProposalNumber || "",
        email: this.correo,
      })
        .then((response) => {
          this.$emit("setDocs", response.email, false);
          this.correo = response.email;
          axios({
            url: `propuestas/${this.ProposalNumber}/firma/digital`,
            method: "POST",
          })
            .then((res) => {
              const session_id = res.data.data.Propuesta.firma.session_id;
              this.$emit("SetSessionId", session_id);
            })
            .catch(() => {
              this.loading.DIG = false;
            })
            .finally(() => {
              this.$emit("EmailSent");
              this.loading = false;
              if (v) {
                this.digitalTokenMsg = "Token enviado exitosamente";
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: this.digitalTokenMsg,
                  top: true,
                  right: true,
                  color: "success",
                });
                this.open = false;
              }
            });
        })
        .catch((message) => {
          this.loading = false;
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

:deep(.v-dialog) {
  overflow: hidden !important;
}
</style>
