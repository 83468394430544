<template name="component-name">
  <v-container>
    <v-row>
      <v-col cols="8" offset="2">
        <v-card outlined class="light br-12">
          <v-card-text>
            <v-container>
              <div
                class="borderBottom d-flex justify-space-between align-center"
              >
                <div class="d-flex">
                  <div class="pb-2 px-0" style="cursor: pointer">
                    <span class="py-2 px-5 h6 item-borderStyle primary--text">
                      Pagador
                    </span>
                  </div>
                </div>
              </div>
              <div class="pt-10">
                <v-form ref="form">
                  <v-layout justify-center>
                    <v-flex xs6 class="mr-4">
                      <LabelComponent text="Rut*" />
                      <v-text-field
                        v-model="new_payer.numero_documento"
                        outlined
                        dense
                        v-mask="rutMask"
                        :rules="[rules.required, rules.rut, rules.isValid]"
                        name="rut_pagador"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 class="ml-4">
                      <LabelComponent text="Nombre*" />
                      <inputText
                        placeholder="Nombre"
                        color="#0077C8"
                        readonly="false"
                        :rules="[rules.required]"
                        :hide="false"
                        :model="new_payer.nombre"
                        @input="new_payer.nombre = $event"
                        name="nombre_pagador"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center class="mt-1">
                    <v-flex xs6 class="mr-4">
                      <LabelComponent text="Primer apellido*" />
                      <inputText
                        placeholder="Primer apellido"
                        color="#0077C8"
                        readonly="false"
                        :rules="[rules.required]"
                        :hide="false"
                        :model="new_payer.primer_apellido"
                        @input="new_payer.primer_apellido = $event"
                        name="fst_apellido_pagador"
                      />
                    </v-flex>
                    <v-flex xs6 class="ml-4">
                      <LabelComponent text="Segundo apellido*" />
                      <inputText
                        placeholder="Segundo apellido"
                        color="#0077C8"
                        readonly="false"
                        :rules="[rules.required]"
                        :hide="false"
                        :model="new_payer.segundo_apellido"
                        @input="new_payer.segundo_apellido = $event"
                        name="snd_apellido_pagador"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center class="mt-1">
                    <v-flex xs6 class="mr-4">
                      <!-- CAMPO DATE CON CALENDARIO -->
                      <!-- <DatePicker
                      label="Fecha de nacimiento"
                      color="#0077c8"
                      :hide="false"
                      :date="new_payer.fecha_nacimiento"
                      @input="new_payer.fecha_nacimiento = $event"
                      :max="true"
                    /> -->
                      <!-- CAMPO DATE SIN CALENDARIO -->
                      <LabelComponent text="Fecha de nacimiento*" />
                      <v-text-field
                        v-model="payerBirthDate"
                        :rules="[rules.required, rules.date, rules.date18]"
                        placeholder="dd/mm/yyyy"
                        v-mask="dateMask"
                        outlined
                        dense
                        name="fecha_nacimiento_pagador"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 class="ml-4">
                      <LabelComponent text="Género*" />
                      <SelectLabel
                        :fields="select_genero"
                        color="#0077c8"
                        :rules="[rules.selectrequired]"
                        name="genero_pagador"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center class="mt-1">
                    <v-flex xs6 class="mr-4">
                      <LabelComponent text="Correo*" />
                      <inputText
                        placeholder="Correo"
                        color="#0077C8"
                        readonly="false"
                        :rules="[rules.required, rules.email]"
                        :hide="false"
                        :model="new_payer.correo"
                        @input="new_payer.correo = $event"
                        name="correo_pagador"
                      />
                    </v-flex>
                    <v-flex xs6 class="ml-4">
                      <LabelComponent text="Teléfono*" />
                      <TextFieldPhone
                        :text="new_payer.telefono"
                        color="#0077C8"
                        :readonly="false"
                        :rules="[rules.required, rules.telefono]"
                        @input="new_payer.telefono = $event"
                        name="telefono_pagador"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout class="" justify-center>
                    <v-flex xs6 class="mr-4">
                      <LabelComponent text="Comuna*" />
                      <SelectLabel
                        :fields="loading_cities ? data : getCities"
                        color="#0077c8"
                        :loading="loading_cities"
                        :rules="[rules.comunarequired]"
                        name="comuna_pagador"
                      />
                    </v-flex>
                    <v-flex xs6 class="ml-4">
                      <LabelComponent text="Dirección*" />
                      <inputText
                        placeholder="Direccion"
                        color="#0077C8"
                        readonly="false"
                        :rules="[rules.required]"
                        :hide="false"
                        :model="new_payer.direccion"
                        @input="new_payer.direccion = $event"
                        name="dir_pagador"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center>
                    <v-flex xs6 class="mr-4">
                      <LabelComponent text="Número*" />
                      <inputText
                        placeholder="Número"
                        color="#0077C8"
                        readonly="false"
                        :rules="[rules.required]"
                        :hide="false"
                        type="number"
                        :model="new_payer.numero"
                        @input="new_payer.numero = $event"
                        name="numero_pagador"
                      />
                    </v-flex>
                    <v-flex xs6 class="ml-4">
                      <LabelComponent text="Departamento/Casa" />
                      <inputText
                        placeholder="Departamento/Casa"
                        color="#0077C8"
                        readonly="false"
                        :hide="false"
                        :model="new_payer.piso"
                        @input="new_payer.piso = $event"
                        name="depto_pagador"
                      />
                    </v-flex>
                  </v-layout>

                  <!-- CAMPO DE INFORMACION EXTRA -->
                  <!-- Descomentar si se requiere nuevamente -->
                  <!-- <v-layout
                  v-if="showOther"
                  justify-start
                  class="mb-15 mt-1 mr-8"
                >
                  <v-flex xs6>
                    <inputText
                      color="#0077c8"
                      readonly="false"
                      label="Otro"
                      size="large"
                    />
                  </v-flex>
                </v-layout>
                <v-layout justify-center class="mt-1">
                  <v-flex xs12>
                    <v-layout justify-start>
                      <v-flex xs6>
                        <v-btn
                          v-if="!showOther"
                          color="primary"
                          text
                          @click="displayOther"
                          small
                        >
                          <v-icon left>bi bi-plus-circle</v-icon> Añadir
                          otro</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout> -->

                  <v-layout justify-center class="mb-10 mt-6">
                    <v-flex xs12 class="text-end">
                      <v-btn
                        color="primary"
                        class="mr-2 text-none"
                        width="140"
                        outlined
                        rounded
                        @click="goBack"
                        :disabled="loading"
                        name="boton_volver"
                        >Volver</v-btn
                      >
                      <v-btn
                        color="primary"
                        class="ml-2 text-none"
                        rounded
                        width="140"
                        @click="goNext"
                        :loading="loading"
                        name="boton_continuar"
                        >Continuar</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-form>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import inputText from "@/components/Inputs/Input";
import SelectLabel from "@/components/Inputs/SelectLabel.vue";
// import DatePicker from "@/components/Inputs/DatePicker";
import { dateToNumber } from "@/store/resources/calc.js";
import LabelComponent from "@/components/Inputs/Label.vue";
import TextFieldPhone from "@/components/Inputs/TextFieldPhone";
import { dateMask } from "@/helpers/mask.js";
import {
  validarFecha,
  validarEmail,
  validarRutFormat,
  validarRut,
  validarFecha18,
} from "@/store/resources/validate.js";
import { rutMask } from "@/helpers/mask.js";
export default {
  props: ["next", "back", "numero_propuesta", "rut_propuesta"],
  data: () => ({
    createPayerSuccess: false,
    createPayerError: false,
    loading: false,
    showOther: false,
    payerBirthDate: "",
    new_payer: {
      tipo_documento: "RUT",
      numero_documento: "",
      nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      fecha_nacimiento: "",
      genero: "",
      comuna: "",
      direccion: "",
      numero: "",
      piso: "",
      correo: "",
      telefono: "",
    },
    readonlyRut: false,
    valid: true,
    loading_cities: true,
    tab: "tab-1",
    data: {
      label: "Comuna",
      answers: [],
      selected: "",
      placeholder: "Comuna",
      rules: [(value) => !!value || "Comuna requerida."],
    },
    select_genero: {
      label: "Género",
      answers: [
        { label: "Masculino", value: "M" },
        { label: "Femenino", value: "F" },
      ],
      selected: "",
      placeholder: "Género",
      rules: [(value) => !!value || "Campo requerido."],
    },
    rules: {
      selectrequired: (value) => !!value || "Campo requerido.",
      comunarequired: (value) => !!value || "Comuna requerido.",
      required: (value) => !!value || "Campo requerido.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => validarEmail(value) || "Correo invalido.",
      telefono: (value) => value.length == 9 || "Teléfono inválido",
      rut: (value) => validarRutFormat(value) || "Formato de RUT invalido",
      date: (value) => validarFecha(value) || "Formato de fecha incorrecto",
      date18: (value) => validarFecha18(value) || "Debe ser mayor de edad",
      isValid: (value) =>
        validarRut(value) || "No se ha logrado reconocer el RUT",
    },
  }),

  created() {
    setTimeout(() => {
      this.loading_cities = false;
    }, 1500);
  },
  methods: {
    ...mapActions("Proposal", ["getPayments"]),
    displayOther() {
      this.showOther = true;
    },
    goBack() {
      this.$emit("back", true);
    },
    goNext() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.loading = true;
        // Eliminar mascaras
        this.new_payer.numero_documento =
          this.new_payer.numero_documento.replace(/[^A-Z0-9]/gi, "");
        this.new_payer.fecha_nacimiento = dateToNumber(this.payerBirthDate);

        this.new_payer.comuna = this.getCities.selected;
        this.new_payer.genero = this.select_genero.selected;

        var dataPayer = {
          numero_propuesta: this.numero_propuesta,
          payer_data: this.new_payer,
        };

        this.createPayer(dataPayer);
      }
    },
    createPayer(data) {
      this.createPayerSuccess = true;
      axios({
        url: `propuestas/${data.numero_propuesta}/pagador`,
        method: "POST",
        data: data.payer_data,
      })
        .then(async () => {
          this.createPayerSuccess = false;

          let nuevo_numero_documento = this.new_payer.numero_documento
            .split(".")
            .join("")
            .split("-")
            .join("");

          let payload = {
            clientRut:
              nuevo_numero_documento != null
                ? nuevo_numero_documento
                : this.rut_propuesta,
            proposalNumber: this.numero_propuesta,
          };
          await this.getPayments(payload);
          this.$emit("next", true);
        })
        .catch(() => {
          this.createPayerSuccess = false;
          this.createPayerError = true;
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapGetters("Proposal", ["getCities"]),
    rutMask: () => rutMask,
    dateMask: () => dateMask,
  },
  components: {
    inputText,
    SelectLabel,
    LabelComponent,
    // DatePicker,
    TextFieldPhone,
  },
};
</script>
<style scoped>
.borderBottom {
  border-bottom: solid 1px #cac4d0;
}
.item-borderStyle {
  border-bottom: 3px solid #0077c8;
}
</style>
