<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h6>Complete los datos que faltan del asegurado</h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" offset="2" style="display: flex; justify-content: center">
        <v-card
          outlined
          class="br-12"
          style="max-width: 665px; padding-bottom: 30px">
          <div class="card-content">
            <v-card-text>
              <v-container>
                <div
                  class="borderBottom d-flex justify-space-between align-center mx-8">
                  <div class="d-flex">
                    <div
                      class="pb-2 px-0"
                      @click="tab = 'tab-1'"
                      style="cursor: pointer">
                      <span
                        class="py-2 px-5 h6"
                        :class="[
                          formError.insured
                            ? 'error--text'
                            : tab === 'tab-1'
                            ? 'primary--text item-borderStyle'
                            : '',
                        ]">
                        Asegurado
                      </span>
                    </div>
                    <div class="d-flex align-center">
                      <div
                        class="pb-2 px-0 align-center"
                        @click="tab = 'tab-2'"
                        style="cursor: pointer">
                        <span
                          v-if="showBenefitTab"
                          class="py-2 px-5 h6"
                          :class="[
                            formError.benefic
                              ? 'error--text'
                              : tab === 'tab-2'
                              ? 'primary--text item-borderStyle'
                              : '',
                          ]">
                          Beneficiado
                        </span>
                      </div>
                      <v-icon
                        class="pb-2"
                        @click="showBenefit(1)"
                        v-if="showBenefitTab"
                        small
                        >mdi-close</v-icon
                      >
                    </div>
                  </div>
                  <div
                    @click="showBenefit(0)"
                    style="cursor: pointer"
                    v-if="
                      !showBenefitTab &&
                      ensuredForm.ensuredInsurance !== 'D' &&
                      country === countries.CL
                    "
                    class="d-flex align-center">
                    <Icon name="add" />
                    <span
                      class="primary--text ml-2 h6"
                      :style="{ color: titleColor }">
                      Agregar persona
                    </span>
                  </div>
                </div>
                <div class="pt-10 px-2">
                  <Insured
                    ref="insuredForm"
                    :interes-asegurable="tarifa?.interes_asegurable"
                    v-show="tab === 'tab-1'"
                    :quotation="quotation"
                    :form-data="
                      data?.beneficiarios?.[0] ??
                      data?.asegurado ??
                      data?.cotizacion ??
                      null
                    "
                    :email="
                      client?.[
                        client?.tipo_persona == 'JUR' ? 'contacto' : 'cliente'
                      ]?.datos_contacto?.email ?? ''
                    "
                    :phone="
                      client?.[
                        client?.tipo_persona == 'JUR' ? 'contacto' : 'cliente'
                      ]?.datos_contacto?.telefono ?? ''
                    " />
                  <Benefict
                    ref="beneficForm"
                    v-show="tab === 'tab-2'"
                    :form-data="data?.beneficiarios?.[1] ?? null" />
                </div>
              </v-container>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Benefict from "./PersonStep/Benefict.vue";
import Insured from "./PersonStep/Insured.vue";
import Icon from "@/components/Icon/Icon.vue";
import StepMixin from "@/Mixins/ContractStepMixin.js";
import { COUNTRIES } from "@/helpers/constants";

import { dateToNumber } from "@/store/resources/calc.js";

export default {
  mixins: [StepMixin],
  props: ["quotation"],
  components: {
    Benefict,
    Insured,
    Icon,
  },

  data() {
    return {
      countries: COUNTRIES,
      show: false,
      tab: "tab-1",
      showBenefitTab: false,
      hideBtn: false,
      formError: {
        insured: false,
        benefic: false,
      },
    };
  },

  created() {
    if (this.data?.beneficiarios?.length > 1) {
      this.showBenefit(0);
    }
  },

  computed: {
    ...mapGetters("Users", ["getTituloColor"]),
    ...mapGetters("Proposal", ["tarifa"]),
    ...mapGetters("Forms", ["ensuredForm"]),
    country() {
      return this.$store.getters.getCountry;
    },
    titleColor() {
      if (this.getTituloColor != null) {
        return this.getTituloColor;
      } else {
        return "#0077c8";
      }
    },
  },

  methods: {
    ...mapActions("Proposal", ["setBeneficiarios"]),

    showBenefit(kind = 1) {
      switch (kind) {
        case 0:
          this.tab = "tab-2";
          this.showBenefitTab = true;
          this.hideBtn = true;
          break;
        case 1:
          this.tab = "tab-1";
          this.showBenefitTab = false;
          this.hideBtn = false;
          break;

        default:
          break;
      }
    },

    goNext() {
      return new Promise((resolve, reject) => {
        // Setear variables de error por defecto
        this.formError.benefic = false;
        this.formError.insured = false;

        // Recuperar data del componente de asegurado
        const refForm1 = this.$refs.insuredForm;

        // Declarar variables booleanas de la validacion de los formularios
        let form1Valid = false,
          form2Valid = false;

        // Validar formulario del asegurado
        form1Valid = refForm1.$refs.form.validate();
        if (form1Valid) {
          // Establecer la data a enviar
          const form1 = { ...refForm1.form };
          // Definir el numero de la cotizacion
          form1.cotizacion = this.data.numero_cotizacion;
          // Validacion del interes asegurable
          form1.interes = form1.interes != "" ? form1.interes : "D";
          // Cambio del formato de fecha para el envio
          form1.fecha_nacimiento = dateToNumber(form1.fecha_nacimiento);

          const formData = {
            beneficiarios: [form1],
          };

          // Verificar si el usuario agrego un beneficiario adicional
          if (this.showBenefitTab) {
            // Recuperar data del componente de beneficiario
            const refForm2 = this.$refs.beneficForm;
            // Validar formulario del beneficiario
            form2Valid = refForm2.$refs.form.validate();
            if (form2Valid) {
              const form2 = { ...refForm2.form };
              form2.cotizacion = this.data.numero_cotizacion;
              form2.numero_documento = form2.numero_documento.replace(
                /[^A-Z0-9]/gi,
                ""
              );
              form2.fecha_nacimiento = dateToNumber(form2.fecha_nacimiento);
              // Agregar informacion del beneficiario a la data para enviar
              formData.beneficiarios.push(form2);
            } else {
              // Mostrar error del formulario de beneficiario
              this.formError.benefic = true;
              if (this.tab !== "tab-2") {
                this.tab = "tab-2";
                this.$vuetify.goTo(0);
              }
              reject();
            }
          }
          // Enviar data
          this.setBeneficiarios({ id: this.proposal, form: formData })
            .then((response) => {
              resolve(response.info);
            })
            .catch((error) => {
              reject(error.info);
            });
        } else {
          // Mostrar error del formulario de asegurado
          this.formError.insured = true;
          if (this.tab !== "tab-1") {
            this.tab = "tab-1";
            this.$vuetify.goTo(0);
          }
          reject();
        }
      });
    },
  },
};
</script>

<style scoped>
.borderBottom {
  border-bottom: solid 1px #cac4d0;
}
.item-borderStyle {
  border-bottom: 3px solid #0077c8;
}
</style>
