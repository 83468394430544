<!-- <template>
    <div>
      <v-layout justify-center>
        <v-flex xs12 md8>
          <v-card class="light px-10 py-5" outlined>
            <v-card-text>
              <v-row no-gutters>
                <v-row align="center" justify="center">
                    <v-img
                        max-height="30px"
                        max-width="30px"
                        id="BackgroundSvgs"
                        src="@/assets/Vector.png"
                    />
                    <p class="titleMpay ml-2 mt-1">Mpay</p>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col v-if="Mpay2 === 0">
                        <p>Por favor reenvié el link de acceso a Mpay al cliente, seleccionado una de las siguientes opciones:</p>
                    </v-col>
                    <v-col v-else>
                        <p>En este momento el cliente se encuentra realizando el proceso de activación y pago en Mpay. <br> Le avisaremos una vez que halla finalizado.</p>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <div v-if="Mpay2 === 0">
                        <v-col cols="12" class="d-flex justify-center">
                            <v-btn width="300px" height="59px"><v-icon class="mr-2" color="primary">mdi-cellphone</v-icon>Reenviar link de acceso por SMS</v-btn>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                            <v-btn width="300px" height="59px"><v-icon class="mr-2" color="primary">mdi-email-outline</v-icon>Reenviar link de acceso al correo</v-btn>
                        </v-col>
                    </div>
                    <div v-else>
                        <v-col>
                            <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                        </v-col>
                    </div>
                </v-row>
              </v-row>
              <v-flex class="mt-7 text-center">
                <v-btn
                    color="primary"
                    rounded
                    outlined
                    @click="goBack"
                    width="140"
                    class="mr-2 text-none"
                    name="boton_volver"
                >Volver</v-btn>
            </v-flex>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
</template> -->
<template>
  <v-container>
    <v-row>
      <v-col cols="8" offset="2">
        <v-card class="light px-10 py-5" outlined>
          <div class="card-content">
            <v-card-text>
              <v-container>
                <v-fade-transition mode="out-in">
                  <v-col
                    class="mt-3"
                    style="justify-content: center; align-content: center">
                    <v-row align="center" justify="center">
                      <v-img
                        max-height="30px"
                        max-width="30px"
                        id="BackgroundSvgs"
                        src="@/assets/Vector.png" />
                      <p class="titleMpay ml-2 mt-1">Mpay</p>
                    </v-row>
                    <v-row
                      justify="center"
                      align-content="center"
                      align="center">
                      <v-col
                        cols="6"
                        style="text-align: center"
                        v-if="Mpay2 === 0">
                        <p style="font-size: 16px; font-weight: 400">
                          Por favor reenvié el link de acceso a Mpay al cliente,
                          seleccionado una de las siguientes opciones:
                        </p>
                      </v-col>
                      <v-col cols="6" style="text-align: center" v-else>
                        <p style="font-size: 16px; font-weight: 400">
                          En este momento el cliente se encuentra realizando el
                          proceso de activación y pago en Mpay. <br />
                          Le avisaremos una vez que halla finalizado.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <div v-if="Mpay2 === 0">
                        <v-col cols="12" class="d-flex justify-center">
                          <v-btn width="300px" height="59px"
                            ><v-icon class="mr-2" color="primary"
                              >mdi-cellphone</v-icon
                            >Reenviar link de acceso por SMS</v-btn
                          >
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <v-btn width="300px" height="59px"
                            ><v-icon class="mr-2" color="primary"
                              >mdi-email-outline</v-icon
                            >Reenviar link de acceso al correo</v-btn
                          >
                        </v-col>
                      </div>
                      <div v-else>
                        <v-col>
                          <v-progress-circular
                            :size="70"
                            :width="7"
                            color="primary"
                            indeterminate></v-progress-circular>
                          <pulse-loader></pulse-loader>
                        </v-col>
                      </div>
                    </v-row>
                    <v-flex class="mt-7 text-center">
                      <v-btn
                        v-if="Mpay2 === 0"
                        color="primary"
                        rounded
                        outlined
                        @click="goBack"
                        width="140"
                        class="mr-2 text-none"
                        name="boton_volver"
                        >Volver</v-btn
                      >
                      <v-col v-else>
                        <v-btn
                          color="red"
                          rounded
                          outlined
                          @click="cancel"
                          width="140"
                          class="mr-2 text-none"
                          name="boton_cancelar"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          color="primary"
                          rounded
                          outlined
                          @click="refresh"
                          width="140"
                          class="mr-2 text-none"
                          name="boton_actualizar"
                          >Actualizar</v-btn
                        >
                      </v-col>
                    </v-flex>
                  </v-col>
                </v-fade-transition>
              </v-container>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MPAY2.0",
  props: {
    payId: {
      type: String,
      default: "",
      description: "Id del medio de pago seleccionado",
    },
    total_pesos: {
      type: Number,
      default: 0,
      description: "Precio total en Pesos",
    },
    total_uf: {
      type: Number,
      default: 0,
      description: "Precio total en UF",
    },
    Mpay2: {
      type: Number,
      default: 0,
      description: "Codigo Recibido",
    },
    proposalNumber: {
      type: [String, Number],
      require: true,
      description: "Numero de la propuesta",
    },
  },
  data() {
    return {
      loading: true,
      color: "blue",
      size: "16px",
    };
  },

  methods: {
    ...mapActions("Proposal", ["getMPay2Estado", "cancelMPay2"]),
    goBack() {
      this.$emit("back", true);
    },

    async refresh() {
      const payload = {
        nPropuesta: this.proposalNumber,
      };
      await this.getMPay2Estado(payload)
        .then((res) => {
          return res;
        })
        .catch();
    },

    async cancel() {
      const payload = {
        nPropuesta: this.proposalNumber,
      };
      await this.cancelMPay2(payload)
        .then((res) => {
          return res;
        })
        .catch();
    },
  },
};
</script>
<style scoped>
.borderBottom {
  border-bottom: solid 1px #cac4d0 !important;
}
.item-borderStyle {
  border-bottom: 3px solid #0077c8 !important;
}

#BackgroundSvgs {
  width: 30px;
  height: 30px;
}

.titleMpay {
  color: #0077c8 !important;
  font-size: 20px;
  font-weight: 600;
}
</style>
