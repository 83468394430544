<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-center">
      <Icon name="warning" size="15px" class="error-text" />
      <span class="error-text ml-1 semiBold">ADVERTENCIA</span>
    </div>
    <span>
      A partir de este punto, ya no se podrá modificar esta propuesta.
    </span>
  </div>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";

export default {
  components: { Icon },
};
</script>
